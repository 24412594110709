import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Drawer, Layout, Menu } from 'antd';

import { useAppContext } from '@/context/appContext';
import logoIcon from '@/style/images/leeram-erp-icon.svg';
import logoText from '@/style/images/logo-text.svg';
import history from '@/utils/history';
import useLanguage from '@/locale/useLanguage';

import {
  SettingOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  FileSyncOutlined,
  DashboardOutlined,
  TeamOutlined,
  UserOutlined,
  CreditCardOutlined,
  MenuOutlined,
  UserAddOutlined,
  FileOutlined,
  SnippetsOutlined
} from '@ant-design/icons';

import { useSelector } from 'react-redux';
import { isNavigationActive } from '@/redux/notify/selectors'

const { Sider } = Layout;
const { SubMenu } = Menu;

export default function Navigation() {
  const active = useSelector(isNavigationActive)
  return !active ? <></> : 
    <>
      <div className="sidebar-wraper">
        <Sidebar collapsible={true} />
      </div>
      <MobileSidebar />
    </>
  
}


function Sidebar({ collapsible }) {

  let location = useLocation();
  const translate = useLanguage();

  const SIDEBAR_MENU = [
    { key: '/', icon: <DashboardOutlined />, title: translate('dashboard') },
    { key: '/lead', icon: <UserAddOutlined />, title: translate('lead')  },
    { key: '/offer', icon: <FileOutlined />, title: translate('offer') },
    { key: '/customer', icon: <CustomerServiceOutlined />, title: translate('customer') },
    { key: '/invoice', icon: <FileTextOutlined />, title: translate('invoice') },
    { key: '/quote', icon: <FileSyncOutlined />, title: translate('quote') },
    { key: '/payment/invoice', icon: <CreditCardOutlined />, title: `${translate('payment')}s` },
    { key: '/item', icon: <SnippetsOutlined />, title: `${translate('item')}s` },
    { key: '/employee', icon: <UserOutlined />, title: `${translate('employee')}s` },
    { key: '/admin', icon: <TeamOutlined />, title: translate('admin') },
  ];

  const SETTINGS_SUBMENU = [
    { key: '/settings', title: translate('general_settings') },
    { key: '/email', title: translate('email_templates') },
    { key: '/payment/mode', title: translate('payment_mode') },
    { key: '/settings/advanced', title: translate('advanced_settings') },
  ];

  const { state: stateApp, appContextAction } = useAppContext();
  const { isNavMenuClose } = stateApp;
  const { navMenu } = appContextAction;
  const [showLogoApp, setLogoApp] = useState(isNavMenuClose);
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    if (location) if (currentPath !== location.pathname) setCurrentPath(location.pathname);
  }, [location, currentPath]);

  useEffect(() => {
    if (isNavMenuClose) {
      setLogoApp(isNavMenuClose);
    }
    const timer = setTimeout(() => {
      if (!isNavMenuClose) {
        setLogoApp(isNavMenuClose);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [isNavMenuClose]);
  const onCollapse = () => {
    navMenu.collapse();
  };

  return (
    <>
      <Sider
        collapsible={collapsible}
        collapsed={collapsible ? isNavMenuClose : collapsible}
        onCollapse={onCollapse}
        className="navigation"
      >
        <div className="logo" onClick={() => history.push('/')} style={{ cursor: 'pointer' }}>
          <img src={logoIcon} alt="Logo" style={{ height: '32px' }} />

          {!showLogoApp && (
            <img
              src={logoText}
              alt="Logo"
              style={{ marginTop: '3px', marginLeft: '10px', height: '29px' }}
            />
          )}
        </div>
        <Menu mode="inline" selectedKeys={[currentPath]}>
          {SIDEBAR_MENU.map((menuItem) => (
            <Menu.Item key={menuItem.key} icon={menuItem.icon}>
              <Link to={menuItem.key} />
              {menuItem.title}
            </Menu.Item>
          ))}
          <SubMenu key={'Settings'} icon={<SettingOutlined />} title={translate('settings')}>
            {SETTINGS_SUBMENU.map((menuItem) => (
              <Menu.Item key={menuItem.key}>
                <Link to={menuItem.key} />
                {menuItem.title}
              </Menu.Item>
            ))}
          </SubMenu>
        </Menu>
      </Sider>
    </>
  );
}

function MobileSidebar() {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <Button type="text" size="large" onClick={showDrawer} className="mobile-sidebar-btn">
        <MenuOutlined />
      </Button>
      <Drawer
        width={200}
        placement="left"
        closable={false}
        onClose={onClose}
        visible={visible}
        className="mobile-sidebar-wraper"
      >
        <Sidebar collapsible={false} />
      </Drawer>
    </>
  );
}
