import { get, find, maxBy } from 'lodash';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);



export const getPlan = (idOrRef, plans) => plans.find(
  p => (idOrRef===p._id.toString()) || (idOrRef===p.reference))

/***
 * Whether subscription hasn't expired yet
 */
export const isActive = (subscription) => 
  dayjs(subscription.dateExpires).isAfter(dayjs())

/***
 * Initial vaidity period of product.
 * Returns `plan.metadata.daysValid` iff 'once' type billing,
 * else returns the interval ie. 'month' | 'year'.
 * Default to 
 */
export const daysValid = (plan, billing) => {
    return billing === 'once' ?
    +get(plan, 'metadata.daysValid', 0): 
    +dayjs.duration(1, billing).as('days')
}

export const getDateExpires = (plan, subscription) => 
  dayjs(subscription && subscription.dateExpires)
    .add(daysValid(plan, subscription.billing), 'days')
    .toString();

export const getPrice = (plan, billing) => 
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'XOF',
    minimumFractionDigits: 0
  }).format(get(plan, billing, 0));

/***
 * Days plan have been subscribed to
 * since ever.
 */
export const daysSubscribed = (plan, subscriptions) => {
  subscriptions
    .filter(s => plan._id.toString()===s.plan)
    .reduce((acc, s) => acc + daysValid(s.plan, s.billing), 0)
}

/***
 * Days before a subscribed plan expires
 * Accounts for all active subscriptions including those having
 * their plans set to Plan.allowMultiple===true
 * @returns
 *    - 0 if plan was never subscribed
 *    - daysLeft to expiry elsewise
 */
export const daysLeft = (plan, subscriptions, billingInterval) => {

  const now = dayjs();
  const duration = daysValid(plan, billingInterval)
  const activeSubscriptions = subscriptions
    .filter(s => plan._id.toString()===s.plan)
    .filter(isActive)

  // delta < daysValid => subscription hasn't started yet.
  const daysLeft = activeSubscriptions.reduce((daysLeft, s) => {
    const delta = dayjs(s.dateExpires).diff(now, 'day');
    return daysLeft + (delta < duration ? delta : duration);
  }, 0)

  // console.log(`??? daysLeft -> plan ref=${plan.reference} (id=${plan._id})`, 
  //   `subs (${ activeSubscriptions.length})=${subscriptions.map(s => s.plan)}`,
  //   `daysLeft=${daysLeft}`,
  // )

  return { daysLeft, subscribed: activeSubscriptions.length }
}


/***
 * Extract sold plan (product) from subscription
 */
export const getProductsFromSubscripion = (plans, subscriptions=[]) => {
  const products = [];
  subscriptions.forEach(s => {
    const plan = getPlan(s.plan, plans);
    if(plan) products.push({
      ...plan,
      billing: s.billing,
      dateExpires: s.dateExpires,
      daysSubscribed: daysSubscribed(plan, subscriptions)
    });
  })
  return products;
}


/***
 * Recommended plans based on subscription history.
 * Uses following rules based on first match:
 * 1. plan was explicitely set as recommended
 * 2. plan has the largest billing cycle across all susbcriptions,
 * 3. plan has the longest subscription
 * 
 */
export const getRecommended = (plans, subscriptions) => {
  
  // insight from subscriptions require active user session
  // or will return undefined. 
  const products = getProductsFromSubscripion(plans, subscriptions)

  return find(plans, 'metadata.recommended') 
      || maxBy(products, 'dateExpires') 
      || maxBy(products, 'daysSubscribed')
}


export const expandTransactions = (transactions, plans, subscriptions) => {
  return transactions.map(t => {
    const s = subscriptions.find(sub => t.subscription._id.toString()===sub._id);
    return { ...t, subscription: (s && { ...s, plan: getPlan(s.plan, plans) }) }
  })
}