import * as actionTypes from './types';
import { merge } from 'lodash';

/**
 * State extract
{
  "cart": [...],
  "plans": [...],
  "account": { 
    "isLoading": false,
    "isSuccess": true,
    "result": {
      
      "reference": "techoutlooks",
      "name": "techoutlooks"
      "email": "tvany@techoutlooks.com"
      "dateCreated": "2023-11-19T14:48:32.030Z"

      "transactions": [...],
      "subscriptions": [...],
      "paymentResult": {
        "payment_token": '5df64dd9c5447739327eb88e1e4ea0ac015555cc262ea308c91acbd4e5c8fb95f4bd0bd7cad877a452f877fa6f51fe74184d00a84ab7f9',
        "payment_url": 'https://checkout.cinetpay.com/payment/5df64dd9c5447739327eb88e1e4ea0ac015555cc262ea308c91acbd4e5c8fb95f4bd0bd7cad877a452f877fa6f51fe74184d00a84ab7f9',
        "subscription": null,
        "isNew": false
      }
    }
  }
}
*/

const INITIAL_KEY_STATE = {
  isLoading: false,
  isSuccess: false,
  result: null,
};

// All plans - distinct from "billing.account.plan"
// which points to plans subscribed on account
const PLANS_INITIAL_KEY_STATE = { 
  ...INITIAL_KEY_STATE, 
  result: [] 
}



/** 
 * Account (keyState==="account)
 * - "state.billing[keyState].result.subscription": currently active subscription  
 * - "state.billing[keyState].result.checkout.subscription": set iff checkout was successful
 */
const ACCOUNT_INITIAL_KEY_STATE = { 
  ...INITIAL_KEY_STATE, 
  result: { 
    subscriptions: [],
    transactions: [], 
    subscription: null,
    checkout: {
      subscription: null,       // successful subscription (paid for, free)
      paymentResult: {          // response from the provider if any
        transaction_id: null,
        payment_url: null,
        payment_token: null,
        payment_status: {},
        subscription: null,
      }
    }
  }
}

export const INITIAL_STATE = {
  cart: INITIAL_KEY_STATE,
  plans: PLANS_INITIAL_KEY_STATE,
  account: ACCOUNT_INITIAL_KEY_STATE,
};


export default function (state = INITIAL_STATE, action) {

  const { payload, keyState } = action;
  switch (action.type) {

    case actionTypes.RESET_STATE:
      return INITIAL_STATE;

    case actionTypes.REQUEST_LOADING:
      return {
        ...state,
        [keyState]: {
          ...state[keyState],
          isLoading: true,
        },
      };
    case actionTypes.REQUEST_FAILED:
      return merge({}, state, { 
        [keyState]: { 
          // result: payload,
          isLoading: false,
          isSuccess: false,
        } 
      });
    case actionTypes.REQUEST_SUCCESS:
      return merge({}, state, { 
        [keyState]: { 
          result: payload,
          isLoading: false, 
          isSuccess: true, 
        } 
      });

    case actionTypes.RESET_ACTION:
      return {
        ...state,
        [keyState]: {
          ...INITIAL_STATE[keyState],
        }
      };
      
    default:
      return state;
  }

  
};

