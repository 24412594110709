// preferred browser language, eg. "en-GH" 
// to display names for currency, region, language, etc.,
// in the user's own language.
const lang = Intl.DateTimeFormat().resolvedOptions().locale


/**
 * countries map in Englih language.
 * Below countries map taken from https://www.npmjs.com/package/country-locale-map
 * Cf. also https://www.npmjs.com/package/countries-list
 * Generate emoji flag like so (https://dev.to/jorik/country-code-to-flag-emoji-a21):
    function getFlagEmoji(countryCode) {
      return countryCode.toUpperCase().replace(/./g, char => 
          String.fromCodePoint(127397 + char.charCodeAt())
      );
    }
 */
export const locales = [
  {
    "name": "Afghanistan",
    "alpha2": "AF",
    "alpha3": "AFG",
    "numeric": "004",
    "locales": [
      "ps-AF",
      "fa-AF",
      "uz-Arab-AF"
    ],
    "default_locale": "ps-AF",
    "currency": "AFN",
    "currency_name": "Afghani",
    "languages": [
      "ps",
      "uz",
      "tk"
    ],
    "capital": "Kabul",
    "emoji": "🇦🇫",
    "emojiU": "U+1F1E6 U+1F1EB",
    "fips": "AF",
    "internet": "AF",
    "continent": "Asia",
    "region": "South Asia"
  },
  {
    "name": "Albania",
    "alpha2": "AL",
    "alpha3": "ALB",
    "numeric": "008",
    "locales": [
      "sq-AL"
    ],
    "default_locale": "sq-AL",
    "currency": "ALL",
    "currency_name": "Lek",
    "languages": [
      "sq"
    ],
    "capital": "Tirana",
    "emoji": "🇦🇱",
    "emojiU": "U+1F1E6 U+1F1F1",
    "fips": "AL",
    "internet": "AL",
    "continent": "Europe",
    "region": "South East Europe"
  },
  {
    "name": "Algeria",
    "alpha2": "DZ",
    "alpha3": "DZA",
    "numeric": "012",
    "locales": [
      "ar-DZ",
      "kab-DZ"
    ],
    "default_locale": "ar-DZ",
    "currency": "DZD",
    "currency_name": "Algerian Dinar",
    "languages": [
      "ar"
    ],
    "capital": "Algiers",
    "emoji": "🇩🇿",
    "emojiU": "U+1F1E9 U+1F1FF",
    "fips": "AG",
    "internet": "DZ",
    "continent": "Africa",
    "region": "Northern Africa"
  },
  {
    "name": "American Samoa",
    "alpha2": "AS",
    "alpha3": "ASM",
    "numeric": "016",
    "locales": [
      "en-AS"
    ],
    "default_locale": "en-AS",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en",
      "sm"
    ],
    "capital": "Pago Pago",
    "emoji": "🇦🇸",
    "emojiU": "U+1F1E6 U+1F1F8",
    "fips": "AQ",
    "internet": "AS",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Andorra",
    "alpha2": "AD",
    "alpha3": "AND",
    "numeric": "020",
    "locales": [
      "ca"
    ],
    "default_locale": "ca",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "ca"
    ],
    "capital": "Andorra la Vella",
    "emoji": "🇦🇩",
    "emojiU": "U+1F1E6 U+1F1E9",
    "fips": "AN",
    "internet": "AD",
    "continent": "Europe",
    "region": "South West Europe"
  },
  {
    "name": "Angola",
    "alpha2": "AO",
    "alpha3": "AGO",
    "numeric": "024",
    "locales": [
      "pt"
    ],
    "default_locale": "pt",
    "currency": "AOA",
    "currency_name": "Kwanza",
    "languages": [
      "pt"
    ],
    "capital": "Luanda",
    "emoji": "🇦🇴",
    "emojiU": "U+1F1E6 U+1F1F4",
    "fips": "AO",
    "internet": "AO",
    "continent": "Africa",
    "region": "Southern Africa"
  },
  {
    "name": "Anguilla",
    "alpha2": "AI",
    "alpha3": "AIA",
    "numeric": "660",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "XCD",
    "currency_name": "East Caribbean Dollar",
    "languages": [
      "en"
    ],
    "capital": "The Valley",
    "emoji": "🇦🇮",
    "emojiU": "U+1F1E6 U+1F1EE",
    "fips": "AV",
    "internet": "AI",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Antarctica",
    "alpha2": "AQ",
    "alpha3": "ATA",
    "numeric": "010",
    "locales": [
      "en-US"
    ],
    "default_locale": "en-US",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [],
    "capital": "",
    "emoji": "🇦🇶",
    "emojiU": "U+1F1E6 U+1F1F6",
    "fips": "AY",
    "internet": "AQ",
    "continent": "Antarctica",
    "region": "Antarctica"
  },
  {
    "name": "Antigua and Barbuda",
    "alpha2": "AG",
    "alpha3": "ATG",
    "numeric": "028",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "XCD",
    "currency_name": "East Caribbean Dollar",
    "languages": [
      "en"
    ],
    "capital": "Saint John's",
    "emoji": "🇦🇬",
    "emojiU": "U+1F1E6 U+1F1EC",
    "fips": "AC",
    "internet": "AG",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Argentina",
    "alpha2": "AR",
    "alpha3": "ARG",
    "numeric": "032",
    "locales": [
      "es-AR"
    ],
    "default_locale": "es-AR",
    "currency": "ARS",
    "currency_name": "Argentine Peso",
    "languages": [
      "es",
      "gn"
    ],
    "capital": "Buenos Aires",
    "emoji": "🇦🇷",
    "emojiU": "U+1F1E6 U+1F1F7",
    "fips": "AR",
    "internet": "AR",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "Armenia",
    "alpha2": "AM",
    "alpha3": "ARM",
    "numeric": "051",
    "locales": [
      "hy-AM"
    ],
    "default_locale": "hy-AM",
    "currency": "AMD",
    "currency_name": "Armenian Dram",
    "languages": [
      "hy",
      "ru"
    ],
    "capital": "Yerevan",
    "emoji": "🇦🇲",
    "emojiU": "U+1F1E6 U+1F1F2",
    "fips": "AM",
    "internet": "AM",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Aruba",
    "alpha2": "AW",
    "alpha3": "ABW",
    "numeric": "533",
    "locales": [
      "nl"
    ],
    "default_locale": "nl",
    "currency": "AWG",
    "currency_name": "Aruban Florin",
    "languages": [
      "nl",
      "pa"
    ],
    "capital": "Oranjestad",
    "emoji": "🇦🇼",
    "emojiU": "U+1F1E6 U+1F1FC",
    "fips": "AA",
    "internet": "AW",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Australia",
    "alpha2": "AU",
    "alpha3": "AUS",
    "numeric": "036",
    "locales": [
      "en-AU"
    ],
    "default_locale": "en-AU",
    "currency": "AUD",
    "currency_name": "Australian Dollar",
    "languages": [
      "en"
    ],
    "capital": "Canberra",
    "emoji": "🇦🇺",
    "emojiU": "U+1F1E6 U+1F1FA",
    "fips": "AS",
    "internet": "AU",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Austria",
    "alpha2": "AT",
    "alpha3": "AUT",
    "numeric": "040",
    "locales": [
      "de-AT"
    ],
    "default_locale": "de-AT",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "de"
    ],
    "capital": "Vienna",
    "emoji": "🇦🇹",
    "emojiU": "U+1F1E6 U+1F1F9",
    "fips": "AU",
    "internet": "AT",
    "continent": "Europe",
    "region": "Central Europe"
  },
  {
    "name": "Azerbaijan",
    "alpha2": "AZ",
    "alpha3": "AZE",
    "numeric": "031",
    "locales": [
      "az-Cyrl-AZ",
      "az-Latn-AZ"
    ],
    "default_locale": "az-Cyrl-AZ",
    "currency": "AZN",
    "currency_name": "Azerbaijan Manat",
    "languages": [
      "az"
    ],
    "capital": "Baku",
    "emoji": "🇦🇿",
    "emojiU": "U+1F1E6 U+1F1FF",
    "fips": "AJ",
    "internet": "AZ",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Bahamas",
    "alpha2": "BS",
    "alpha3": "BHS",
    "numeric": "044",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "BSD",
    "currency_name": "Bahamian Dollar",
    "languages": [
      "en"
    ],
    "capital": "Nassau",
    "emoji": "🇧🇸",
    "emojiU": "U+1F1E7 U+1F1F8",
    "fips": "BF",
    "internet": "BS",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Bahrain",
    "alpha2": "BH",
    "alpha3": "BHR",
    "numeric": "048",
    "locales": [
      "ar-BH"
    ],
    "default_locale": "ar-BH",
    "currency": "BHD",
    "currency_name": "Bahraini Dinar",
    "languages": [
      "ar"
    ],
    "capital": "Manama",
    "emoji": "🇧🇭",
    "emojiU": "U+1F1E7 U+1F1ED",
    "fips": "BA",
    "internet": "BH",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Bangladesh",
    "alpha2": "BD",
    "alpha3": "BGD",
    "numeric": "050",
    "locales": [
      "bn-BD"
    ],
    "default_locale": "bn-BD",
    "currency": "BDT",
    "currency_name": "Taka",
    "languages": [
      "bn"
    ],
    "capital": "Dhaka",
    "emoji": "🇧🇩",
    "emojiU": "U+1F1E7 U+1F1E9",
    "fips": "BG",
    "internet": "BD",
    "continent": "Asia",
    "region": "South Asia"
  },
  {
    "name": "Barbados",
    "alpha2": "BB",
    "alpha3": "BRB",
    "numeric": "052",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "BBD",
    "currency_name": "Barbados Dollar",
    "languages": [
      "en"
    ],
    "capital": "Bridgetown",
    "emoji": "🇧🇧",
    "emojiU": "U+1F1E7 U+1F1E7",
    "fips": "BB",
    "internet": "BB",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Belarus",
    "alpha2": "BY",
    "alpha3": "BLR",
    "numeric": "112",
    "locales": [
      "be-BY"
    ],
    "default_locale": "be-BY",
    "currency": "BYN",
    "currency_name": "Belarusian Ruble",
    "languages": [
      "be",
      "ru"
    ],
    "capital": "Minsk",
    "emoji": "🇧🇾",
    "emojiU": "U+1F1E7 U+1F1FE",
    "fips": "BO",
    "internet": "BY",
    "continent": "Europe",
    "region": "Eastern Europe"
  },
  {
    "name": "Belgium",
    "alpha2": "BE",
    "alpha3": "BEL",
    "numeric": "056",
    "locales": [
      "nl-BE",
      "en-BE",
      "fr-BE",
      "de-BE"
    ],
    "default_locale": "nl-BE",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "nl",
      "fr",
      "de"
    ],
    "capital": "Brussels",
    "emoji": "🇧🇪",
    "emojiU": "U+1F1E7 U+1F1EA",
    "fips": "BE",
    "internet": "BE",
    "continent": "Europe",
    "region": "Western Europe"
  },
  {
    "name": "Belize",
    "alpha2": "BZ",
    "alpha3": "BLZ",
    "numeric": "084",
    "locales": [
      "en-BZ"
    ],
    "default_locale": "en-BZ",
    "currency": "BZD",
    "currency_name": "Belize Dollar",
    "languages": [
      "en",
      "es"
    ],
    "capital": "Belmopan",
    "emoji": "🇧🇿",
    "emojiU": "U+1F1E7 U+1F1FF",
    "fips": "BH",
    "internet": "BZ",
    "continent": "Americas",
    "region": "Central America"
  },
  {
    "name": "Benin",
    "alpha2": "BJ",
    "alpha3": "BEN",
    "numeric": "204",
    "locales": [
      "fr-BJ"
    ],
    "default_locale": "fr-BJ",
    "currency": "XOF",
    "currency_name": "CFA Franc BCEAO",
    "languages": [
      "fr"
    ],
    "capital": "Porto-Novo",
    "emoji": "🇧🇯",
    "emojiU": "U+1F1E7 U+1F1EF",
    "fips": "BN",
    "internet": "BJ",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Bermuda",
    "alpha2": "BM",
    "alpha3": "BMU",
    "numeric": "060",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "BMD",
    "currency_name": "Bermudian Dollar",
    "languages": [
      "en"
    ],
    "capital": "Hamilton",
    "emoji": "🇧🇲",
    "emojiU": "U+1F1E7 U+1F1F2",
    "fips": "BD",
    "internet": "BM",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Bhutan",
    "alpha2": "BT",
    "alpha3": "BTN",
    "numeric": "064",
    "locales": [
      "dz"
    ],
    "default_locale": "dz",
    "currency": "BTN",
    "currency_name": "Ngultrum",
    "languages": [
      "dz"
    ],
    "capital": "Thimphu",
    "emoji": "🇧🇹",
    "emojiU": "U+1F1E7 U+1F1F9",
    "fips": "BT",
    "internet": "BT",
    "continent": "Asia",
    "region": "South Asia"
  },
  {
    "name": "Bolivia",
    "alpha2": "BO",
    "alpha3": "BOL",
    "numeric": "068",
    "locales": [
      "es-BO"
    ],
    "default_locale": "es-BO",
    "currency": "BTN",
    "currency_name": "Ngultrum",
    "languages": [
      "es",
      "ay",
      "qu"
    ],
    "capital": "Sucre",
    "emoji": "🇧🇴",
    "emojiU": "U+1F1E7 U+1F1F4",
    "fips": "BL",
    "internet": "BO",
    "continent": "Americas",
    "region": "South America",
    "alternate_names": [
      "Plurinational State of Bolivia"
    ]
  },
  {
    "name": "Bonaire",
    "alpha2": "BQ",
    "alpha3": "BES",
    "numeric": "535",
    "locales": [
      "nl"
    ],
    "default_locale": "nl",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "nl"
    ],
    "capital": "Kralendijk",
    "emoji": "🇧🇶",
    "emojiU": "U+1F1E7 U+1F1F6",
    "fips": "BQ",
    "internet": "BQ",
    "continent": "Americas",
    "region": "West Indies",
    "alternate_names": [
      "Bonaire, Sint Eustatius and Saba"
    ]
  },
  {
    "name": "Bosnia and Herzegovina",
    "alpha2": "BA",
    "alpha3": "BIH",
    "numeric": "070",
    "locales": [
      "bs-BA",
      "sr-Cyrl-BA",
      "sr-Latn-BA"
    ],
    "default_locale": "bs-BA",
    "currency": "BAM",
    "currency_name": "Convertible Mark",
    "languages": [
      "bs",
      "hr",
      "sr"
    ],
    "capital": "Sarajevo",
    "emoji": "🇧🇦",
    "emojiU": "U+1F1E7 U+1F1E6",
    "fips": "BK",
    "internet": "BA",
    "continent": "Europe",
    "region": "South East Europe"
  },
  {
    "name": "Botswana",
    "alpha2": "BW",
    "alpha3": "BWA",
    "numeric": "072",
    "locales": [
      "en-BW"
    ],
    "default_locale": "en-BW",
    "currency": "BWP",
    "currency_name": "Pula",
    "languages": [
      "en",
      "tn"
    ],
    "capital": "Gaborone",
    "emoji": "🇧🇼",
    "emojiU": "U+1F1E7 U+1F1FC",
    "fips": "BC",
    "internet": "BW",
    "continent": "Africa",
    "region": "Southern Africa"
  },
  {
    "name": "Bouvet Island",
    "alpha2": "BV",
    "alpha3": "BVT",
    "numeric": "074",
    "locales": [
      "no"
    ],
    "default_locale": "no",
    "currency": "NOK",
    "currency_name": "Norwegian Krone",
    "languages": [
      "no",
      "nb",
      "nn"
    ],
    "capital": "",
    "emoji": "🇧🇻",
    "emojiU": "U+1F1E7 U+1F1FB",
    "fips": "BV",
    "internet": "BV",
    "continent": "Atlantic Ocean",
    "region": "South Atlantic Ocean"
  },
  {
    "name": "Brazil",
    "alpha2": "BR",
    "alpha3": "BRA",
    "numeric": "076",
    "locales": [
      "pt-BR"
    ],
    "default_locale": "pt-BR",
    "currency": "BRL",
    "currency_name": "Brazilian Real",
    "languages": [
      "pt"
    ],
    "capital": "Brasília",
    "emoji": "🇧🇷",
    "emojiU": "U+1F1E7 U+1F1F7",
    "fips": "BR",
    "internet": "BR",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "British Indian Ocean Territory",
    "alpha2": "IO",
    "alpha3": "IOT",
    "numeric": "086",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en"
    ],
    "capital": "Diego Garcia",
    "emoji": "🇮🇴",
    "emojiU": "U+1F1EE U+1F1F4",
    "fips": "IO",
    "internet": "IO",
    "continent": "Asia",
    "region": "South Asia"
  },
  {
    "name": "Brunei Darussalam",
    "alpha2": "BN",
    "alpha3": "BRN",
    "numeric": "096",
    "locales": [
      "ms-BN"
    ],
    "default_locale": "ms-BN",
    "currency": "BND",
    "currency_name": "Brunei Dollar",
    "languages": [
      "ms"
    ],
    "capital": "Bandar Seri Begawan",
    "emoji": "🇧🇳",
    "emojiU": "U+1F1E7 U+1F1F3",
    "fips": "BX",
    "internet": "BN",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Bulgaria",
    "alpha2": "BG",
    "alpha3": "BGR",
    "numeric": "100",
    "locales": [
      "bg-BG"
    ],
    "default_locale": "bg-BG",
    "currency": "BGN",
    "currency_name": "Bulgarian Lev",
    "languages": [
      "bg"
    ],
    "capital": "Sofia",
    "emoji": "🇧🇬",
    "emojiU": "U+1F1E7 U+1F1EC",
    "fips": "BU",
    "internet": "BG",
    "continent": "Europe",
    "region": "South East Europe"
  },
  {
    "name": "Burkina Faso",
    "alpha2": "BF",
    "alpha3": "BFA",
    "numeric": "854",
    "locales": [
      "fr-BF"
    ],
    "default_locale": "fr-BF",
    "currency": "XOF",
    "currency_name": "CFA Franc BCEAO",
    "languages": [
      "fr",
      "ff"
    ],
    "capital": "Ouagadougou",
    "emoji": "🇧🇫",
    "emojiU": "U+1F1E7 U+1F1EB",
    "fips": "UV",
    "internet": "BF",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Burundi",
    "alpha2": "BI",
    "alpha3": "BDI",
    "numeric": "108",
    "locales": [
      "fr-BI"
    ],
    "default_locale": "fr-BI",
    "currency": "BIF",
    "currency_name": "Burundi Franc",
    "languages": [
      "fr",
      "rn"
    ],
    "capital": "Bujumbura",
    "emoji": "🇧🇮",
    "emojiU": "U+1F1E7 U+1F1EE",
    "fips": "BY",
    "internet": "BI",
    "continent": "Africa",
    "region": "Central Africa"
  },
  {
    "name": "Cabo Verde",
    "alpha2": "CV",
    "alpha3": "CPV",
    "numeric": "132",
    "locales": [
      "kea-CV"
    ],
    "default_locale": "kea-CV",
    "currency": "CVE",
    "currency_name": "Cabo Verde Escudo",
    "languages": [
      "pt"
    ],
    "capital": "Praia",
    "emoji": "🇨🇻",
    "emojiU": "U+1F1E8 U+1F1FB",
    "fips": "CV",
    "internet": "CV",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Cambodia",
    "alpha2": "KH",
    "alpha3": "KHM",
    "numeric": "116",
    "locales": [
      "km-KH"
    ],
    "default_locale": "km-KH",
    "currency": "KHR",
    "currency_name": "Riel",
    "languages": [
      "km"
    ],
    "capital": "Phnom Penh",
    "emoji": "🇰🇭",
    "emojiU": "U+1F1F0 U+1F1ED",
    "fips": "CB",
    "internet": "KH",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Cameroon",
    "alpha2": "CM",
    "alpha3": "CMR",
    "numeric": "120",
    "locales": [
      "fr-CM"
    ],
    "default_locale": "fr-CM",
    "currency": "XAF",
    "currency_name": "CFA Franc BEAC",
    "languages": [
      "en",
      "fr"
    ],
    "capital": "Yaoundé",
    "emoji": "🇨🇲",
    "emojiU": "U+1F1E8 U+1F1F2",
    "fips": "CM",
    "internet": "CM",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Canada",
    "alpha2": "CA",
    "alpha3": "CAN",
    "numeric": "124",
    "locales": [
      "en-CA",
      "fr-CA"
    ],
    "default_locale": "en-CA",
    "currency": "CAD",
    "currency_name": "Canadian Dollar",
    "languages": [
      "en",
      "fr"
    ],
    "capital": "Ottawa",
    "emoji": "🇨🇦",
    "emojiU": "U+1F1E8 U+1F1E6",
    "fips": "CA",
    "internet": "CA",
    "continent": "Americas",
    "region": "North America"
  },
  {
    "name": "Cayman Islands",
    "alpha2": "KY",
    "alpha3": "CYM",
    "numeric": "136",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "KYD",
    "currency_name": "Cayman Islands Dollar",
    "languages": [
      "en"
    ],
    "capital": "George Town",
    "emoji": "🇰🇾",
    "emojiU": "U+1F1F0 U+1F1FE",
    "fips": "CJ",
    "internet": "KY",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Central African Republic",
    "alpha2": "CF",
    "alpha3": "CAF",
    "numeric": "140",
    "locales": [
      "fr-CF",
      "sg-CF"
    ],
    "default_locale": "fr-CF",
    "currency": "XAF",
    "currency_name": "CFA Franc BEAC",
    "languages": [
      "fr",
      "sg"
    ],
    "capital": "Bangui",
    "emoji": "🇨🇫",
    "emojiU": "U+1F1E8 U+1F1EB",
    "fips": "CT",
    "internet": "CF",
    "continent": "Africa",
    "region": "Central Africa"
  },
  {
    "name": "Chad",
    "alpha2": "TD",
    "alpha3": "TCD",
    "numeric": "148",
    "locales": [
      "fr-TD"
    ],
    "default_locale": "fr-TD",
    "currency": "XAF",
    "currency_name": "CFA Franc BEAC",
    "languages": [
      "fr",
      "ar"
    ],
    "capital": "N'Djamena",
    "emoji": "🇹🇩",
    "emojiU": "U+1F1F9 U+1F1E9",
    "fips": "CD",
    "internet": "TD",
    "continent": "Africa",
    "region": "Central Africa"
  },
  {
    "name": "Chile",
    "alpha2": "CL",
    "alpha3": "CHL",
    "numeric": "152",
    "locales": [
      "es-CL"
    ],
    "default_locale": "es-CL",
    "currency": "CLF",
    "currency_name": "Unidad de Fomento",
    "languages": [
      "es"
    ],
    "capital": "Santiago",
    "emoji": "🇨🇱",
    "emojiU": "U+1F1E8 U+1F1F1",
    "fips": "CI",
    "internet": "CL",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "China",
    "alpha2": "CN",
    "alpha3": "CHN",
    "numeric": "156",
    "locales": [
      "zh-CN",
      "zh-Hans-CN",
      "ii-CN",
      "bo-CN"
    ],
    "default_locale": "zh-CN",
    "currency": "CNY",
    "currency_name": "Yuan Renminbi",
    "languages": [
      "zh"
    ],
    "capital": "Beijing",
    "emoji": "🇨🇳",
    "emojiU": "U+1F1E8 U+1F1F3",
    "fips": "CH",
    "internet": "CN",
    "continent": "Asia",
    "region": "East Asia"
  },
  {
    "name": "Christmas Island",
    "alpha2": "CX",
    "alpha3": "CXR",
    "numeric": "162",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "AUD",
    "currency_name": "Australian Dollar",
    "languages": [
      "en"
    ],
    "capital": "Flying Fish Cove",
    "emoji": "🇨🇽",
    "emojiU": "U+1F1E8 U+1F1FD",
    "fips": "KT",
    "internet": "CX",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Cocos Islands",
    "alpha2": "CC",
    "alpha3": "CCK",
    "numeric": "166",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "AUD",
    "currency_name": "Australian Dollar",
    "languages": [
      "en"
    ],
    "capital": "West Island",
    "emoji": "🇨🇨",
    "emojiU": "U+1F1E8 U+1F1E8",
    "fips": "CK",
    "internet": "CC",
    "continent": "Asia",
    "region": "South East Asia",
    "alternate_names": [
      "Cocos Keeling Islands"
    ]
  },
  {
    "name": "Colombia",
    "alpha2": "CO",
    "alpha3": "COL",
    "numeric": "170",
    "locales": [
      "es-CO"
    ],
    "default_locale": "es-CO",
    "currency": "COU",
    "currency_name": "Unidad de Valor Real",
    "languages": [
      "es"
    ],
    "capital": "Bogotá",
    "emoji": "🇨🇴",
    "emojiU": "U+1F1E8 U+1F1F4",
    "fips": "CO",
    "internet": "CO",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "Comoros",
    "alpha2": "KM",
    "alpha3": "COM",
    "numeric": "174",
    "locales": [
      "fr-KM"
    ],
    "default_locale": "fr-KM",
    "currency": "KMF",
    "currency_name": "Comorian Franc ",
    "languages": [
      "ar",
      "fr"
    ],
    "capital": "Moroni",
    "emoji": "🇰🇲",
    "emojiU": "U+1F1F0 U+1F1F2",
    "fips": "CN",
    "internet": "KM",
    "continent": "Africa",
    "region": "Indian Ocean"
  },
  {
    "name": "Democratic Republic of the Congo",
    "alpha2": "CD",
    "alpha3": "COD",
    "numeric": "180",
    "locales": [
      "fr-CD"
    ],
    "default_locale": "fr-CD",
    "currency": "CDF",
    "currency_name": "Congolese Franc",
    "languages": [
      "fr",
      "ln",
      "kg",
      "sw",
      "lu"
    ],
    "capital": "Kinshasa",
    "emoji": "🇨🇩",
    "emojiU": "U+1F1E8 U+1F1E9",
    "fips": "CG",
    "internet": "ZR",
    "continent": "Africa",
    "region": "Central Africa"
  },
  {
    "name": "Congo",
    "alpha2": "CG",
    "alpha3": "COG",
    "numeric": "178",
    "locales": [
      "fr-CG"
    ],
    "default_locale": "fr-CG",
    "currency": "XAF",
    "currency_name": "CFA Franc BEAC",
    "languages": [
      "fr",
      "ln"
    ],
    "capital": "Brazzaville",
    "emoji": "🇨🇬",
    "emojiU": "U+1F1E8 U+1F1EC",
    "fips": "CF",
    "internet": "CG",
    "continent": "Africa",
    "region": "Central Africa"
  },
  {
    "name": "Cook Islands",
    "alpha2": "CK",
    "alpha3": "COK",
    "numeric": "184",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "NZD",
    "currency_name": "New Zealand Dollar",
    "languages": [
      "en"
    ],
    "capital": "Avarua",
    "emoji": "🇨🇰",
    "emojiU": "U+1F1E8 U+1F1F0",
    "fips": "CW",
    "internet": "CK",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Costa Rica",
    "alpha2": "CR",
    "alpha3": "CRI",
    "numeric": "188",
    "locales": [
      "es-CR"
    ],
    "default_locale": "es-CR",
    "currency": "CRC",
    "currency_name": "Costa Rican Colon",
    "languages": [
      "es"
    ],
    "capital": "San José",
    "emoji": "🇨🇷",
    "emojiU": "U+1F1E8 U+1F1F7",
    "fips": "CS",
    "internet": "CR",
    "continent": "Americas",
    "region": "Central America"
  },
  {
    "name": "Croatia",
    "alpha2": "HR",
    "alpha3": "HRV",
    "numeric": "191",
    "locales": [
      "hr-HR"
    ],
    "default_locale": "hr-HR",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "hr"
    ],
    "capital": "Zagreb",
    "emoji": "🇭🇷",
    "emojiU": "U+1F1ED U+1F1F7",
    "fips": "HR",
    "internet": "HR",
    "continent": "Europe",
    "region": "South East Europe"
  },
  {
    "name": "Cuba",
    "alpha2": "CU",
    "alpha3": "CUB",
    "numeric": "192",
    "locales": [
      "es"
    ],
    "default_locale": "es",
    "currency": "CUC",
    "currency_name": "Peso Convertible",
    "languages": [
      "es"
    ],
    "capital": "Havana",
    "emoji": "🇨🇺",
    "emojiU": "U+1F1E8 U+1F1FA",
    "fips": "CU",
    "internet": "CU",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Curaçao",
    "alpha2": "CW",
    "alpha3": "CUW",
    "numeric": "531",
    "locales": [
      "nl"
    ],
    "default_locale": "nl",
    "currency": "ANG",
    "currency_name": "Netherlands Antillean Guilder",
    "languages": [
      "nl",
      "pa",
      "en"
    ],
    "capital": "Willemstad",
    "emoji": "🇨🇼",
    "emojiU": "U+1F1E8 U+1F1FC",
    "fips": "UC",
    "internet": "CW",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Cyprus",
    "alpha2": "CY",
    "alpha3": "CYP",
    "numeric": "196",
    "locales": [
      "el-CY"
    ],
    "default_locale": "el-CY",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "el",
      "tr",
      "hy"
    ],
    "capital": "Nicosia",
    "emoji": "🇨🇾",
    "emojiU": "U+1F1E8 U+1F1FE",
    "fips": "CY",
    "internet": "CY",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Czechia",
    "alpha2": "CZ",
    "alpha3": "CZE",
    "numeric": "203",
    "locales": [
      "cs-CZ"
    ],
    "default_locale": "cs-CZ",
    "currency": "CZK",
    "currency_name": "Czech Koruna",
    "languages": [
      "cs",
      "sk"
    ],
    "capital": "Prague",
    "emoji": "🇨🇿",
    "emojiU": "U+1F1E8 U+1F1FF",
    "fips": "EZ",
    "internet": "CZ",
    "continent": "Europe",
    "region": "Central Europe"
  },
  {
    "name": "Côte d'Ivoire",
    "alpha2": "CI",
    "alpha3": "CIV",
    "numeric": "384",
    "locales": [
      "fr-CI"
    ],
    "default_locale": "fr-CI",
    "currency": "CZK",
    "currency_name": "Czech Koruna",
    "languages": [
      "fr"
    ],
    "capital": "Yamoussoukro",
    "emoji": "🇨🇮",
    "emojiU": "U+1F1E8 U+1F1EE",
    "fips": "IV",
    "internet": "CI",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Denmark",
    "alpha2": "DK",
    "alpha3": "DNK",
    "numeric": "208",
    "locales": [
      "da-DK"
    ],
    "default_locale": "da-DK",
    "currency": "DKK",
    "currency_name": "Danish Krone",
    "languages": [
      "da"
    ],
    "capital": "Copenhagen",
    "emoji": "🇩🇰",
    "emojiU": "U+1F1E9 U+1F1F0",
    "fips": "DA",
    "internet": "DK",
    "continent": "Europe",
    "region": "Northern Europe"
  },
  {
    "name": "Djibouti",
    "alpha2": "DJ",
    "alpha3": "DJI",
    "numeric": "262",
    "locales": [
      "fr-DJ",
      "so-DJ"
    ],
    "default_locale": "fr-DJ",
    "currency": "DJF",
    "currency_name": "Djibouti Franc",
    "languages": [
      "fr",
      "ar"
    ],
    "capital": "Djibouti",
    "emoji": "🇩🇯",
    "emojiU": "U+1F1E9 U+1F1EF",
    "fips": "DJ",
    "internet": "DJ",
    "continent": "Africa",
    "region": "Eastern Africa"
  },
  {
    "name": "Dominica",
    "alpha2": "DM",
    "alpha3": "DMA",
    "numeric": "212",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "XCD",
    "currency_name": "East Caribbean Dollar",
    "languages": [
      "en"
    ],
    "capital": "Roseau",
    "emoji": "🇩🇲",
    "emojiU": "U+1F1E9 U+1F1F2",
    "fips": "DO",
    "internet": "DM",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Dominican Republic",
    "alpha2": "DO",
    "alpha3": "DOM",
    "numeric": "214",
    "locales": [
      "es-DO"
    ],
    "default_locale": "es-DO",
    "currency": "DOP",
    "currency_name": "Dominican Peso",
    "languages": [
      "es"
    ],
    "capital": "Santo Domingo",
    "emoji": "🇩🇴",
    "emojiU": "U+1F1E9 U+1F1F4",
    "fips": "DR",
    "internet": "DO",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Ecuador",
    "alpha2": "EC",
    "alpha3": "ECU",
    "numeric": "218",
    "locales": [
      "es-EC"
    ],
    "default_locale": "es-EC",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "es"
    ],
    "capital": "Quito",
    "emoji": "🇪🇨",
    "emojiU": "U+1F1EA U+1F1E8",
    "fips": "EC",
    "internet": "EC",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "Egypt",
    "alpha2": "EG",
    "alpha3": "EGY",
    "numeric": "818",
    "locales": [
      "ar-EG"
    ],
    "default_locale": "ar-EG",
    "currency": "EGP",
    "currency_name": "Egyptian Pound",
    "languages": [
      "ar"
    ],
    "capital": "Cairo",
    "emoji": "🇪🇬",
    "emojiU": "U+1F1EA U+1F1EC",
    "fips": "EG",
    "internet": "EG",
    "continent": "Africa",
    "region": "Northern Africa"
  },
  {
    "name": "El Salvador",
    "alpha2": "SV",
    "alpha3": "SLV",
    "numeric": "222",
    "locales": [
      "es-SV"
    ],
    "default_locale": "es-SV",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "es"
    ],
    "capital": "San Salvador",
    "emoji": "🇸🇻",
    "emojiU": "U+1F1F8 U+1F1FB",
    "fips": "ES",
    "internet": "SV",
    "continent": "Americas",
    "region": "Central America"
  },
  {
    "name": "Equatorial Guinea",
    "alpha2": "GQ",
    "alpha3": "GNQ",
    "numeric": "226",
    "locales": [
      "fr-GQ",
      "es-GQ"
    ],
    "default_locale": "fr-GQ",
    "currency": "XAF",
    "currency_name": "CFA Franc BEAC",
    "languages": [
      "es",
      "fr"
    ],
    "capital": "Malabo",
    "emoji": "🇬🇶",
    "emojiU": "U+1F1EC U+1F1F6",
    "fips": "EK",
    "internet": "GQ",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Eritrea",
    "alpha2": "ER",
    "alpha3": "ERI",
    "numeric": "232",
    "locales": [
      "ti-ER"
    ],
    "default_locale": "ti-ER",
    "currency": "ERN",
    "currency_name": "Nakfa",
    "languages": [
      "ti",
      "ar",
      "en"
    ],
    "capital": "Asmara",
    "emoji": "🇪🇷",
    "emojiU": "U+1F1EA U+1F1F7",
    "fips": "ER",
    "internet": "ER",
    "continent": "Africa",
    "region": "Eastern Africa"
  },
  {
    "name": "Estonia",
    "alpha2": "EE",
    "alpha3": "EST",
    "numeric": "233",
    "locales": [
      "et-EE"
    ],
    "default_locale": "et-EE",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "et"
    ],
    "capital": "Tallinn",
    "emoji": "🇪🇪",
    "emojiU": "U+1F1EA U+1F1EA",
    "fips": "EN",
    "internet": "EE",
    "continent": "Europe",
    "region": "Eastern Europe"
  },
  {
    "name": "Eswatini",
    "alpha2": "SZ",
    "alpha3": "SWZ",
    "numeric": "748",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "en",
      "ss"
    ],
    "capital": "Lobamba",
    "emoji": "🇸🇿",
    "emojiU": "U+1F1F8 U+1F1FF",
    "fips": "WZ",
    "internet": "SZ",
    "continent": "Africa",
    "region": "Southern Africa"
  },
  {
    "name": "Ethiopia",
    "alpha2": "ET",
    "alpha3": "ETH",
    "numeric": "231",
    "locales": [
      "am-ET",
      "om-ET",
      "so-ET",
      "ti-ET"
    ],
    "default_locale": "am-ET",
    "currency": "ETB",
    "currency_name": "Ethiopian Birr",
    "languages": [
      "am"
    ],
    "capital": "Addis Ababa",
    "emoji": "🇪🇹",
    "emojiU": "U+1F1EA U+1F1F9",
    "fips": "ET",
    "internet": "ET",
    "continent": "Africa",
    "region": "Eastern Africa"
  },
  {
    "name": "Falkland Islands",
    "alpha2": "FK",
    "alpha3": "FLK",
    "numeric": "238",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "DKK",
    "currency_name": "Danish Krone",
    "languages": [
      "en"
    ],
    "capital": "Stanley",
    "emoji": "🇫🇰",
    "emojiU": "U+1F1EB U+1F1F0",
    "fips": "FA",
    "internet": "FK",
    "continent": "Americas",
    "region": "South America",
    "alternate_names": [
      "Malvinas Falkland Islands"
    ]
  },
  {
    "name": "Faroe Islands",
    "alpha2": "FO",
    "alpha3": "FRO",
    "numeric": "234",
    "locales": [
      "fo-FO"
    ],
    "default_locale": "fo-FO",
    "currency": "DKK",
    "currency_name": "Danish Krone",
    "languages": [
      "fo"
    ],
    "capital": "Tórshavn",
    "emoji": "🇫🇴",
    "emojiU": "U+1F1EB U+1F1F4",
    "fips": "FO",
    "internet": "FO",
    "continent": "Europe",
    "region": "Northern Europe"
  },
  {
    "name": "Fiji",
    "alpha2": "FJ",
    "alpha3": "FJI",
    "numeric": "242",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "FJD",
    "currency_name": "Fiji Dollar",
    "languages": [
      "en",
      "fj",
      "hi",
      "ur"
    ],
    "capital": "Suva",
    "emoji": "🇫🇯",
    "emojiU": "U+1F1EB U+1F1EF",
    "fips": "FJ",
    "internet": "FJ",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Finland",
    "alpha2": "FI",
    "alpha3": "FIN",
    "numeric": "246",
    "locales": [
      "fi-FI",
      "sv-FI"
    ],
    "default_locale": "fi-FI",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fi",
      "sv"
    ],
    "capital": "Helsinki",
    "emoji": "🇫🇮",
    "emojiU": "U+1F1EB U+1F1EE",
    "fips": "FI",
    "internet": "FI",
    "continent": "Europe",
    "region": "Northern Europe"
  },
  {
    "name": "France",
    "alpha2": "FR",
    "alpha3": "FRA",
    "numeric": "250",
    "locales": [
      "fr-FR"
    ],
    "default_locale": "fr-FR",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fr"
    ],
    "capital": "Paris",
    "emoji": "🇫🇷",
    "emojiU": "U+1F1EB U+1F1F7",
    "fips": "FR",
    "internet": "FR",
    "continent": "Europe",
    "region": "Western Europe"
  },
  {
    "name": "French Guiana",
    "alpha2": "GF",
    "alpha3": "GUF",
    "numeric": "254",
    "locales": [
      "fr"
    ],
    "default_locale": "fr",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fr"
    ],
    "capital": "Cayenne",
    "emoji": "🇬🇫",
    "emojiU": "U+1F1EC U+1F1EB",
    "fips": "FG",
    "internet": "GF",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "French Polynesia",
    "alpha2": "PF",
    "alpha3": "PYF",
    "numeric": "258",
    "locales": [
      "fr"
    ],
    "default_locale": "fr",
    "currency": "XPF",
    "currency_name": "CFP Franc",
    "languages": [
      "fr"
    ],
    "capital": "Papeetē",
    "emoji": "🇵🇫",
    "emojiU": "U+1F1F5 U+1F1EB",
    "fips": "FP",
    "internet": "PF",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "French Southern Territories",
    "alpha2": "TF",
    "alpha3": "ATF",
    "numeric": "260",
    "locales": [
      "fr"
    ],
    "default_locale": "fr",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fr"
    ],
    "capital": "Port-aux-Français",
    "emoji": "🇹🇫",
    "emojiU": "U+1F1F9 U+1F1EB",
    "fips": "FS",
    "internet": "--",
    "continent": "Indian Ocean",
    "region": "Southern Indian Ocean"
  },
  {
    "name": "Gabon",
    "alpha2": "GA",
    "alpha3": "GAB",
    "numeric": "266",
    "locales": [
      "fr-GA"
    ],
    "default_locale": "fr-GA",
    "currency": "XAF",
    "currency_name": "CFA Franc BEAC",
    "languages": [
      "fr"
    ],
    "capital": "Libreville",
    "emoji": "🇬🇦",
    "emojiU": "U+1F1EC U+1F1E6",
    "fips": "GB",
    "internet": "GA",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Gambia",
    "alpha2": "GM",
    "alpha3": "GMB",
    "numeric": "270",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "GMD",
    "currency_name": "Dalasi",
    "languages": [
      "en"
    ],
    "capital": "Banjul",
    "emoji": "🇬🇲",
    "emojiU": "U+1F1EC U+1F1F2",
    "fips": "GA",
    "internet": "GM",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Georgia",
    "alpha2": "GE",
    "alpha3": "GEO",
    "numeric": "268",
    "locales": [
      "ka-GE"
    ],
    "default_locale": "ka-GE",
    "currency": "GEL",
    "currency_name": "Lari",
    "languages": [
      "ka"
    ],
    "capital": "Tbilisi",
    "emoji": "🇬🇪",
    "emojiU": "U+1F1EC U+1F1EA",
    "fips": "GG",
    "internet": "GE",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Germany",
    "alpha2": "DE",
    "alpha3": "DEU",
    "numeric": "276",
    "locales": [
      "de-DE"
    ],
    "default_locale": "de-DE",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "de"
    ],
    "capital": "Berlin",
    "emoji": "🇩🇪",
    "emojiU": "U+1F1E9 U+1F1EA",
    "fips": "GM",
    "internet": "DE",
    "continent": "Europe",
    "region": "Western Europe"
  },
  {
    "name": "Ghana",
    "alpha2": "GH",
    "alpha3": "GHA",
    "numeric": "288",
    "locales": [
      "ak-GH",
      "ee-GH",
      "ha-Latn-GH"
    ],
    "default_locale": "ak-GH",
    "currency": "GHS",
    "currency_name": "Ghana Cedi",
    "languages": [
      "en"
    ],
    "capital": "Accra",
    "emoji": "🇬🇭",
    "emojiU": "U+1F1EC U+1F1ED",
    "fips": "GH",
    "internet": "GH",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Gibraltar",
    "alpha2": "GI",
    "alpha3": "GIB",
    "numeric": "292",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "GIP",
    "currency_name": "Gibraltar Pound",
    "languages": [
      "en"
    ],
    "capital": "Gibraltar",
    "emoji": "🇬🇮",
    "emojiU": "U+1F1EC U+1F1EE",
    "fips": "GI",
    "internet": "GI",
    "continent": "Europe",
    "region": "South West Europe"
  },
  {
    "name": "Greece",
    "alpha2": "GR",
    "alpha3": "GRC",
    "numeric": "300",
    "locales": [
      "el-GR"
    ],
    "default_locale": "el-GR",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "el"
    ],
    "capital": "Athens",
    "emoji": "🇬🇷",
    "emojiU": "U+1F1EC U+1F1F7",
    "fips": "GR",
    "internet": "GR",
    "continent": "Europe",
    "region": "South East Europe"
  },
  {
    "name": "Greenland",
    "alpha2": "GL",
    "alpha3": "GRL",
    "numeric": "304",
    "locales": [
      "kl-GL"
    ],
    "default_locale": "kl-GL",
    "currency": "DKK",
    "currency_name": "Danish Krone",
    "languages": [
      "kl"
    ],
    "capital": "Nuuk",
    "emoji": "🇬🇱",
    "emojiU": "U+1F1EC U+1F1F1",
    "fips": "GL",
    "internet": "GL",
    "continent": "Americas",
    "region": "North America"
  },
  {
    "name": "Grenada",
    "alpha2": "GD",
    "alpha3": "GRD",
    "numeric": "308",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "XCD",
    "currency_name": "East Caribbean Dollar",
    "languages": [
      "en"
    ],
    "capital": "St. George's",
    "emoji": "🇬🇩",
    "emojiU": "U+1F1EC U+1F1E9",
    "fips": "GJ",
    "internet": "GD",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Guadeloupe",
    "alpha2": "GP",
    "alpha3": "GLP",
    "numeric": "312",
    "locales": [
      "fr-GP"
    ],
    "default_locale": "fr-GP",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fr"
    ],
    "capital": "Basse-Terre",
    "emoji": "🇬🇵",
    "emojiU": "U+1F1EC U+1F1F5",
    "fips": "GP",
    "internet": "GP",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Guam",
    "alpha2": "GU",
    "alpha3": "GUM",
    "numeric": "316",
    "locales": [
      "en-GU"
    ],
    "default_locale": "en-GU",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en",
      "ch",
      "es"
    ],
    "capital": "Hagåtña",
    "emoji": "🇬🇺",
    "emojiU": "U+1F1EC U+1F1FA",
    "fips": "GQ",
    "internet": "GU",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Guatemala",
    "alpha2": "GT",
    "alpha3": "GTM",
    "numeric": "320",
    "locales": [
      "es-GT"
    ],
    "default_locale": "es-GT",
    "currency": "GTQ",
    "currency_name": "Quetzal",
    "languages": [
      "es"
    ],
    "capital": "Guatemala City",
    "emoji": "🇬🇹",
    "emojiU": "U+1F1EC U+1F1F9",
    "fips": "GT",
    "internet": "GT",
    "continent": "Americas",
    "region": "Central America"
  },
  {
    "name": "Guernsey",
    "alpha2": "GG",
    "alpha3": "GGY",
    "numeric": "831",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "GBP",
    "currency_name": "Pound Sterling",
    "languages": [
      "en",
      "fr"
    ],
    "capital": "St. Peter Port",
    "emoji": "🇬🇬",
    "emojiU": "U+1F1EC U+1F1EC",
    "fips": "GK",
    "internet": "GG",
    "continent": "Europe",
    "region": "Western Europe"
  },
  {
    "name": "Guinea",
    "alpha2": "GN",
    "alpha3": "GIN",
    "numeric": "324",
    "locales": [
      "fr-GN"
    ],
    "default_locale": "fr-GN",
    "currency": "GNF",
    "currency_name": "Guinean Franc",
    "languages": [
      "fr",
      "ff"
    ],
    "capital": "Conakry",
    "emoji": "🇬🇳",
    "emojiU": "U+1F1EC U+1F1F3",
    "fips": "GV",
    "internet": "GN",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Guinea-Bissau",
    "alpha2": "GW",
    "alpha3": "GNB",
    "numeric": "624",
    "locales": [
      "pt-GW"
    ],
    "default_locale": "pt-GW",
    "currency": "XOF",
    "currency_name": "CFA Franc BCEAO",
    "languages": [
      "pt"
    ],
    "capital": "Bissau",
    "emoji": "🇬🇼",
    "emojiU": "U+1F1EC U+1F1FC",
    "fips": "PU",
    "internet": "GW",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Guyana",
    "alpha2": "GY",
    "alpha3": "GUY",
    "numeric": "328",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "GYD",
    "currency_name": "Guyana Dollar",
    "languages": [
      "en"
    ],
    "capital": "Georgetown",
    "emoji": "🇬🇾",
    "emojiU": "U+1F1EC U+1F1FE",
    "fips": "GY",
    "internet": "GY",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "Haiti",
    "alpha2": "HT",
    "alpha3": "HTI",
    "numeric": "332",
    "locales": [
      "fr"
    ],
    "default_locale": "fr",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "fr",
      "ht"
    ],
    "capital": "Port-au-Prince",
    "emoji": "🇭🇹",
    "emojiU": "U+1F1ED U+1F1F9",
    "fips": "HA",
    "internet": "HT",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Heard Island and McDonald Islands",
    "alpha2": "HM",
    "alpha3": "HMD",
    "numeric": "334",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "AUD",
    "currency_name": "Australian Dollar",
    "languages": [
      "en"
    ],
    "capital": "",
    "emoji": "🇭🇲",
    "emojiU": "U+1F1ED U+1F1F2",
    "fips": "HM",
    "internet": "HM",
    "continent": "Indian Ocean",
    "region": "Southern Indian Ocean"
  },
  {
    "name": "Holy See",
    "alpha2": "VA",
    "alpha3": "VAT",
    "numeric": "336",
    "locales": [
      "it"
    ],
    "default_locale": "it",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "it",
      "la"
    ],
    "capital": "Vatican City",
    "emoji": "🇻🇦",
    "emojiU": "U+1F1FB U+1F1E6",
    "fips": "VT",
    "internet": "VA",
    "continent": "Europe",
    "region": "Southern Europe"
  },
  {
    "name": "Honduras",
    "alpha2": "HN",
    "alpha3": "HND",
    "numeric": "340",
    "locales": [
      "es-HN"
    ],
    "default_locale": "es-HN",
    "currency": "HNL",
    "currency_name": "Lempira",
    "languages": [
      "es"
    ],
    "capital": "Tegucigalpa",
    "emoji": "🇭🇳",
    "emojiU": "U+1F1ED U+1F1F3",
    "fips": "HO",
    "internet": "HN",
    "continent": "Americas",
    "region": "Central America"
  },
  {
    "name": "Hong Kong",
    "alpha2": "HK",
    "alpha3": "HKG",
    "numeric": "344",
    "locales": [
      "yue-Hant-HK",
      "zh-Hans-HK",
      "zh-Hant-HK",
      "en-HK"
    ],
    "default_locale": "en-HK",
    "currency": "HKD",
    "currency_name": "Hong Kong Dollar",
    "languages": [
      "zh",
      "en"
    ],
    "capital": "City of Victoria",
    "emoji": "🇭🇰",
    "emojiU": "U+1F1ED U+1F1F0",
    "fips": "HK",
    "internet": "HK",
    "continent": "Asia",
    "region": "East Asia"
  },
  {
    "name": "Hungary",
    "alpha2": "HU",
    "alpha3": "HUN",
    "numeric": "348",
    "locales": [
      "hu-HU"
    ],
    "default_locale": "hu-HU",
    "currency": "HUF",
    "currency_name": "Forint",
    "languages": [
      "hu"
    ],
    "capital": "Budapest",
    "emoji": "🇭🇺",
    "emojiU": "U+1F1ED U+1F1FA",
    "fips": "HU",
    "internet": "HU",
    "continent": "Europe",
    "region": "Central Europe"
  },
  {
    "name": "Iceland",
    "alpha2": "IS",
    "alpha3": "ISL",
    "numeric": "352",
    "locales": [
      "is-IS"
    ],
    "default_locale": "is-IS",
    "currency": "ISK",
    "currency_name": "Iceland Krona",
    "languages": [
      "is"
    ],
    "capital": "Reykjavik",
    "emoji": "🇮🇸",
    "emojiU": "U+1F1EE U+1F1F8",
    "fips": "IC",
    "internet": "IS",
    "continent": "Europe",
    "region": "Northern Europe"
  },
  {
    "name": "India",
    "alpha2": "IN",
    "alpha3": "IND",
    "numeric": "356",
    "locales": [
      "as-IN",
      "bn-IN",
      "en-IN",
      "gu-IN",
      "hi-IN",
      "kn-IN",
      "kok-IN",
      "ml-IN",
      "mr-IN",
      "ne-IN",
      "or-IN",
      "pa-Guru-IN",
      "ta-IN",
      "te-IN",
      "bo-IN",
      "ur-IN"
    ],
    "default_locale": "hi-IN",
    "currency": "INR",
    "currency_name": "Indian Rupee",
    "languages": [
      "hi",
      "en"
    ],
    "capital": "New Delhi",
    "emoji": "🇮🇳",
    "emojiU": "U+1F1EE U+1F1F3",
    "fips": "IN",
    "internet": "IN",
    "continent": "Asia",
    "region": "South Asia"
  },
  {
    "name": "Indonesia",
    "alpha2": "ID",
    "alpha3": "IDN",
    "numeric": "360",
    "locales": [
      "id-ID"
    ],
    "default_locale": "id-ID",
    "currency": "IDR",
    "currency_name": "Rupiah",
    "languages": [
      "id"
    ],
    "capital": "Jakarta",
    "emoji": "🇮🇩",
    "emojiU": "U+1F1EE U+1F1E9",
    "fips": "ID",
    "internet": "ID",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Iran",
    "alpha2": "IR",
    "alpha3": "IRN",
    "numeric": "364",
    "locales": [
      "fa-IR"
    ],
    "default_locale": "fa-IR",
    "currency": "XDR",
    "currency_name": "SDR (Special Drawing Right)",
    "languages": [
      "fa"
    ],
    "capital": "Tehran",
    "emoji": "🇮🇷",
    "emojiU": "U+1F1EE U+1F1F7",
    "fips": "IR",
    "internet": "IR",
    "continent": "Asia",
    "region": "South West Asia",
    "alternate_names": [
      "Islamic Republic of Iran"
    ]
  },
  {
    "name": "Iraq",
    "alpha2": "IQ",
    "alpha3": "IRQ",
    "numeric": "368",
    "locales": [
      "ar-IQ"
    ],
    "default_locale": "ar-IQ",
    "currency": "IQD",
    "currency_name": "Iraqi Dinar",
    "languages": [
      "ar",
      "ku"
    ],
    "capital": "Baghdad",
    "emoji": "🇮🇶",
    "emojiU": "U+1F1EE U+1F1F6",
    "fips": "IZ",
    "internet": "IQ",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Ireland",
    "alpha2": "IE",
    "alpha3": "IRL",
    "numeric": "372",
    "locales": [
      "en-IE",
      "ga-IE"
    ],
    "default_locale": "en-IE",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "ga",
      "en"
    ],
    "capital": "Dublin",
    "emoji": "🇮🇪",
    "emojiU": "U+1F1EE U+1F1EA",
    "fips": "EI",
    "internet": "IE",
    "continent": "Europe",
    "region": "Western Europe"
  },
  {
    "name": "Isle of Man",
    "alpha2": "IM",
    "alpha3": "IMN",
    "numeric": "833",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "GBP",
    "currency_name": "Pound Sterling",
    "languages": [
      "en",
      "gv"
    ],
    "capital": "Douglas",
    "emoji": "🇮🇲",
    "emojiU": "U+1F1EE U+1F1F2",
    "fips": "IM",
    "internet": "IM",
    "continent": "Europe",
    "region": "Western Europe"
  },
  {
    "name": "Israel",
    "alpha2": "IL",
    "alpha3": "ISR",
    "numeric": "376",
    "locales": [
      "en-IL",
      "he-IL"
    ],
    "default_locale": "he-IL",
    "currency": "ILS",
    "currency_name": "New Israeli Sheqel",
    "languages": [
      "he",
      "ar"
    ],
    "capital": "Jerusalem",
    "emoji": "🇮🇱",
    "emojiU": "U+1F1EE U+1F1F1",
    "fips": "IS",
    "internet": "IL",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Italy",
    "alpha2": "IT",
    "alpha3": "ITA",
    "numeric": "380",
    "locales": [
      "it-IT"
    ],
    "default_locale": "it-IT",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "it"
    ],
    "capital": "Rome",
    "emoji": "🇮🇹",
    "emojiU": "U+1F1EE U+1F1F9",
    "fips": "IT",
    "internet": "IT",
    "continent": "Europe",
    "region": "Southern Europe"
  },
  {
    "name": "Jamaica",
    "alpha2": "JM",
    "alpha3": "JAM",
    "numeric": "388",
    "locales": [
      "en-JM"
    ],
    "default_locale": "en-JM",
    "currency": "JMD",
    "currency_name": "Jamaican Dollar",
    "languages": [
      "en"
    ],
    "capital": "Kingston",
    "emoji": "🇯🇲",
    "emojiU": "U+1F1EF U+1F1F2",
    "fips": "JM",
    "internet": "JM",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Japan",
    "alpha2": "JP",
    "alpha3": "JPN",
    "numeric": "392",
    "locales": [
      "ja-JP"
    ],
    "default_locale": "ja-JP",
    "currency": "JPY",
    "currency_name": "Yen",
    "languages": [
      "ja"
    ],
    "capital": "Tokyo",
    "emoji": "🇯🇵",
    "emojiU": "U+1F1EF U+1F1F5",
    "fips": "JA",
    "internet": "JP",
    "continent": "Asia",
    "region": "East Asia"
  },
  {
    "name": "Jersey",
    "alpha2": "JE",
    "alpha3": "JEY",
    "numeric": "832",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "GBP",
    "currency_name": "Pound Sterling",
    "languages": [
      "en",
      "fr"
    ],
    "capital": "Saint Helier",
    "emoji": "🇯🇪",
    "emojiU": "U+1F1EF U+1F1EA",
    "fips": "JE",
    "internet": "JE",
    "continent": "Europe",
    "region": "Western Europe"
  },
  {
    "name": "Jordan",
    "alpha2": "JO",
    "alpha3": "JOR",
    "numeric": "400",
    "locales": [
      "ar-JO"
    ],
    "default_locale": "ar-JO",
    "currency": "JOD",
    "currency_name": "Jordanian Dinar",
    "languages": [
      "ar"
    ],
    "capital": "Amman",
    "emoji": "🇯🇴",
    "emojiU": "U+1F1EF U+1F1F4",
    "fips": "JO",
    "internet": "JO",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Kazakhstan",
    "alpha2": "KZ",
    "alpha3": "KAZ",
    "numeric": "398",
    "locales": [
      "kk-Cyrl-KZ"
    ],
    "default_locale": "kk-Cyrl-KZ",
    "currency": "KZT",
    "currency_name": "Tenge",
    "languages": [
      "kk",
      "ru"
    ],
    "capital": "Astana",
    "emoji": "🇰🇿",
    "emojiU": "U+1F1F0 U+1F1FF",
    "fips": "KZ",
    "internet": "KZ",
    "continent": "Asia",
    "region": "Central Asia"
  },
  {
    "name": "Kenya",
    "alpha2": "KE",
    "alpha3": "KEN",
    "numeric": "404",
    "locales": [
      "ebu-KE",
      "guz-KE",
      "kln-KE",
      "kam-KE",
      "ki-KE",
      "luo-KE",
      "luy-KE",
      "mas-KE",
      "mer-KE",
      "om-KE",
      "saq-KE",
      "so-KE",
      "sw-KE",
      "dav-KE",
      "teo-KE"
    ],
    "default_locale": "ebu-KE",
    "currency": "KES",
    "currency_name": "Kenyan Shilling",
    "languages": [
      "en",
      "sw"
    ],
    "capital": "Nairobi",
    "emoji": "🇰🇪",
    "emojiU": "U+1F1F0 U+1F1EA",
    "fips": "KE",
    "internet": "KE",
    "continent": "Africa",
    "region": "Eastern Africa"
  },
  {
    "name": "Kiribati",
    "alpha2": "KI",
    "alpha3": "KIR",
    "numeric": "296",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "AUD",
    "currency_name": "Australian Dollar",
    "languages": [
      "en"
    ],
    "capital": "South Tarawa",
    "emoji": "🇰🇮",
    "emojiU": "U+1F1F0 U+1F1EE",
    "fips": "KR",
    "internet": "KI",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "North Korea",
    "alpha2": "KP",
    "alpha3": "PRK",
    "numeric": "408",
    "locales": [
      "ko"
    ],
    "default_locale": "ko",
    "currency": "KPW",
    "currency_name": "North Korean Won",
    "languages": [
      "ko"
    ],
    "capital": "Pyongyang",
    "emoji": "🇰🇵",
    "emojiU": "U+1F1F0 U+1F1F5",
    "fips": "KN",
    "internet": "KP",
    "continent": "Asia",
    "region": "East Asia",
    "alternate_names": [
      "Democratic People's Republic of Korea"
    ]
  },
  {
    "name": "South Korea",
    "alpha2": "KR",
    "alpha3": "KOR",
    "numeric": "410",
    "locales": [
      "ko-KR"
    ],
    "default_locale": "ko-KR",
    "currency": "KRW",
    "currency_name": "South Korean Won",
    "languages": [
      "ko"
    ],
    "capital": "Seoul",
    "emoji": "🇰🇷",
    "emojiU": "U+1F1F0 U+1F1F7",
    "fips": "KS",
    "internet": "KR",
    "continent": "Asia",
    "region": "East Asia",
    "alternate_names": [
      "Republic of Korea"
    ]
  },
  {
    "name": "Kuwait",
    "alpha2": "KW",
    "alpha3": "KWT",
    "numeric": "414",
    "locales": [
      "ar-KW"
    ],
    "default_locale": "ar-KW",
    "currency": "KWD",
    "currency_name": "Kuwaiti Dinar",
    "languages": [
      "ar"
    ],
    "capital": "Kuwait City",
    "emoji": "🇰🇼",
    "emojiU": "U+1F1F0 U+1F1FC",
    "fips": "KU",
    "internet": "KW",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Kyrgyzstan",
    "alpha2": "KG",
    "alpha3": "KGZ",
    "numeric": "417",
    "locales": [
      "ky"
    ],
    "default_locale": "ky",
    "currency": "KGS",
    "currency_name": "Som",
    "languages": [
      "ky",
      "ru"
    ],
    "capital": "Bishkek",
    "emoji": "🇰🇬",
    "emojiU": "U+1F1F0 U+1F1EC",
    "fips": "KG",
    "internet": "KG",
    "continent": "Asia",
    "region": "Central Asia"
  },
  {
    "name": "Lao People's Democratic Republic",
    "alpha2": "LA",
    "alpha3": "LAO",
    "numeric": "418",
    "locales": [
      "lo"
    ],
    "default_locale": "lo",
    "currency": "LAK",
    "currency_name": "Lao Kip",
    "languages": [
      "lo"
    ],
    "capital": "Vientiane",
    "emoji": "🇱🇦",
    "emojiU": "U+1F1F1 U+1F1E6",
    "fips": "LA",
    "internet": "LA",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Latvia",
    "alpha2": "LV",
    "alpha3": "LVA",
    "numeric": "428",
    "locales": [
      "lv-LV"
    ],
    "default_locale": "lv-LV",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "lv"
    ],
    "capital": "Riga",
    "emoji": "🇱🇻",
    "emojiU": "U+1F1F1 U+1F1FB",
    "fips": "LG",
    "internet": "LV",
    "continent": "Europe",
    "region": "Eastern Europe"
  },
  {
    "name": "Lebanon",
    "alpha2": "LB",
    "alpha3": "LBN",
    "numeric": "422",
    "locales": [
      "ar-LB"
    ],
    "default_locale": "ar-LB",
    "currency": "LBP",
    "currency_name": "Lebanese Pound",
    "languages": [
      "ar",
      "fr"
    ],
    "capital": "Beirut",
    "emoji": "🇱🇧",
    "emojiU": "U+1F1F1 U+1F1E7",
    "fips": "LE",
    "internet": "LB",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Lesotho",
    "alpha2": "LS",
    "alpha3": "LSO",
    "numeric": "426",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "ZAR",
    "currency_name": "Rand",
    "languages": [
      "en",
      "st"
    ],
    "capital": "Maseru",
    "emoji": "🇱🇸",
    "emojiU": "U+1F1F1 U+1F1F8",
    "fips": "LT",
    "internet": "LS",
    "continent": "Africa",
    "region": "Southern Africa"
  },
  {
    "name": "Liberia",
    "alpha2": "LR",
    "alpha3": "LBR",
    "numeric": "430",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "LRD",
    "currency_name": "Liberian Dollar",
    "languages": [
      "en"
    ],
    "capital": "Monrovia",
    "emoji": "🇱🇷",
    "emojiU": "U+1F1F1 U+1F1F7",
    "fips": "LI",
    "internet": "LR",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Libya",
    "alpha2": "LY",
    "alpha3": "LBY",
    "numeric": "434",
    "locales": [
      "ar-LY"
    ],
    "default_locale": "ar-LY",
    "currency": "LYD",
    "currency_name": "Libyan Dinar",
    "languages": [
      "ar"
    ],
    "capital": "Tripoli",
    "emoji": "🇱🇾",
    "emojiU": "U+1F1F1 U+1F1FE",
    "fips": "LY",
    "internet": "LY",
    "continent": "Africa",
    "region": "Northern Africa"
  },
  {
    "name": "Liechtenstein",
    "alpha2": "LI",
    "alpha3": "LIE",
    "numeric": "438",
    "locales": [
      "de-LI"
    ],
    "default_locale": "de-LI",
    "currency": "CHF",
    "currency_name": "Swiss Franc",
    "languages": [
      "de"
    ],
    "capital": "Vaduz",
    "emoji": "🇱🇮",
    "emojiU": "U+1F1F1 U+1F1EE",
    "fips": "LS",
    "internet": "LI",
    "continent": "Europe",
    "region": "Central Europe"
  },
  {
    "name": "Lithuania",
    "alpha2": "LT",
    "alpha3": "LTU",
    "numeric": "440",
    "locales": [
      "lt-LT"
    ],
    "default_locale": "lt-LT",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "lt"
    ],
    "capital": "Vilnius",
    "emoji": "🇱🇹",
    "emojiU": "U+1F1F1 U+1F1F9",
    "fips": "LH",
    "internet": "LT",
    "continent": "Europe",
    "region": "Eastern Europe"
  },
  {
    "name": "Luxembourg",
    "alpha2": "LU",
    "alpha3": "LUX",
    "numeric": "442",
    "locales": [
      "fr-LU",
      "de-LU"
    ],
    "default_locale": "fr-LU",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fr",
      "de",
      "lb"
    ],
    "capital": "Luxembourg",
    "emoji": "🇱🇺",
    "emojiU": "U+1F1F1 U+1F1FA",
    "fips": "LU",
    "internet": "LU",
    "continent": "Europe",
    "region": "Western Europe"
  },
  {
    "name": "Macao",
    "alpha2": "MO",
    "alpha3": "MAC",
    "numeric": "446",
    "locales": [
      "zh-Hans-MO",
      "zh-Hant-MO"
    ],
    "default_locale": "zh-Hans-MO",
    "currency": "MOP",
    "currency_name": "Pataca",
    "languages": [
      "zh",
      "pt"
    ],
    "capital": "",
    "emoji": "🇲🇴",
    "emojiU": "U+1F1F2 U+1F1F4",
    "fips": "MC",
    "internet": "MO",
    "continent": "Asia",
    "region": "East Asia"
  },
  {
    "name": "Madagascar",
    "alpha2": "MG",
    "alpha3": "MDG",
    "numeric": "450",
    "locales": [
      "fr-MG",
      "mg-MG"
    ],
    "default_locale": "fr-MG",
    "currency": "MGA",
    "currency_name": "Malagasy Ariary",
    "languages": [
      "fr",
      "mg"
    ],
    "capital": "Antananarivo",
    "emoji": "🇲🇬",
    "emojiU": "U+1F1F2 U+1F1EC",
    "fips": "MA",
    "internet": "MG",
    "continent": "Africa",
    "region": "Indian Ocean"
  },
  {
    "name": "Malawi",
    "alpha2": "MW",
    "alpha3": "MWI",
    "numeric": "454",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "MWK",
    "currency_name": "Malawi Kwacha",
    "languages": [
      "en",
      "ny"
    ],
    "capital": "Lilongwe",
    "emoji": "🇲🇼",
    "emojiU": "U+1F1F2 U+1F1FC",
    "fips": "MI",
    "internet": "MW",
    "continent": "Africa",
    "region": "Southern Africa"
  },
  {
    "name": "Malaysia",
    "alpha2": "MY",
    "alpha3": "MYS",
    "numeric": "458",
    "locales": [
      "ms-MY"
    ],
    "default_locale": "ms-MY",
    "currency": "MYR",
    "currency_name": "Malaysian Ringgit",
    "languages": [
      "ms"
    ],
    "capital": "Kuala Lumpur",
    "emoji": "🇲🇾",
    "emojiU": "U+1F1F2 U+1F1FE",
    "fips": "MY",
    "internet": "MY",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Maldives",
    "alpha2": "MV",
    "alpha3": "MDV",
    "numeric": "462",
    "locales": [
      "dv"
    ],
    "default_locale": "dv",
    "currency": "MVR",
    "currency_name": "Rufiyaa",
    "languages": [
      "dv"
    ],
    "capital": "Malé",
    "emoji": "🇲🇻",
    "emojiU": "U+1F1F2 U+1F1FB",
    "fips": "MV",
    "internet": "MV",
    "continent": "Asia",
    "region": "South Asia"
  },
  {
    "name": "Mali",
    "alpha2": "ML",
    "alpha3": "MLI",
    "numeric": "466",
    "locales": [
      "bm-ML",
      "fr-ML",
      "khq-ML",
      "ses-ML"
    ],
    "default_locale": "fr-ML",
    "currency": "XOF",
    "currency_name": "CFA Franc BCEAO",
    "languages": [
      "fr"
    ],
    "capital": "Bamako",
    "emoji": "🇲🇱",
    "emojiU": "U+1F1F2 U+1F1F1",
    "fips": "ML",
    "internet": "ML",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Malta",
    "alpha2": "MT",
    "alpha3": "MLT",
    "numeric": "470",
    "locales": [
      "en-MT",
      "mt-MT"
    ],
    "default_locale": "en-MT",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "mt",
      "en"
    ],
    "capital": "Valletta",
    "emoji": "🇲🇹",
    "emojiU": "U+1F1F2 U+1F1F9",
    "fips": "MT",
    "internet": "MT",
    "continent": "Europe",
    "region": "Southern Europe"
  },
  {
    "name": "Marshall Islands",
    "alpha2": "MH",
    "alpha3": "MHL",
    "numeric": "584",
    "locales": [
      "en-MH"
    ],
    "default_locale": "en-MH",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en",
      "mh"
    ],
    "capital": "Majuro",
    "emoji": "🇲🇭",
    "emojiU": "U+1F1F2 U+1F1ED",
    "fips": "RM",
    "internet": "MH",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Martinique",
    "alpha2": "MQ",
    "alpha3": "MTQ",
    "numeric": "474",
    "locales": [
      "fr-MQ"
    ],
    "default_locale": "fr-MQ",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fr"
    ],
    "capital": "Fort-de-France",
    "emoji": "🇲🇶",
    "emojiU": "U+1F1F2 U+1F1F6",
    "fips": "MB",
    "internet": "MQ",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Mauritania",
    "alpha2": "MR",
    "alpha3": "MRT",
    "numeric": "478",
    "locales": [
      "ar"
    ],
    "default_locale": "ar",
    "currency": "MRU",
    "currency_name": "Ouguiya",
    "languages": [
      "ar"
    ],
    "capital": "Nouakchott",
    "emoji": "🇲🇷",
    "emojiU": "U+1F1F2 U+1F1F7",
    "fips": "MR",
    "internet": "MR",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Mauritius",
    "alpha2": "MU",
    "alpha3": "MUS",
    "numeric": "480",
    "locales": [
      "en-MU",
      "mfe-MU"
    ],
    "default_locale": "en-MU",
    "currency": "MUR",
    "currency_name": "Mauritius Rupee",
    "languages": [
      "en"
    ],
    "capital": "Port Louis",
    "emoji": "🇲🇺",
    "emojiU": "U+1F1F2 U+1F1FA",
    "fips": "MP",
    "internet": "MU",
    "continent": "Africa",
    "region": "Indian Ocean"
  },
  {
    "name": "Mayotte",
    "alpha2": "YT",
    "alpha3": "MYT",
    "numeric": "175",
    "locales": [
      "fr"
    ],
    "default_locale": "fr",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fr"
    ],
    "capital": "Mamoudzou",
    "emoji": "🇾🇹",
    "emojiU": "U+1F1FE U+1F1F9",
    "fips": "MF",
    "internet": "YT",
    "continent": "Africa",
    "region": "Indian Ocean"
  },
  {
    "name": "Mexico",
    "alpha2": "MX",
    "alpha3": "MEX",
    "numeric": "484",
    "locales": [
      "es-MX"
    ],
    "default_locale": "es-MX",
    "currency": "MXN",
    "currency_name": "Mexican Peso",
    "languages": [
      "es"
    ],
    "capital": "Mexico City",
    "emoji": "🇲🇽",
    "emojiU": "U+1F1F2 U+1F1FD",
    "fips": "MX",
    "internet": "MX",
    "continent": "Americas",
    "region": "Central America"
  },
  {
    "name": "Micronesia",
    "alpha2": "FM",
    "alpha3": "FSM",
    "numeric": "583",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "RUB",
    "currency_name": "Russian Ruble",
    "languages": [
      "en"
    ],
    "capital": "Palikir",
    "emoji": "🇫🇲",
    "emojiU": "U+1F1EB U+1F1F2",
    "fips": "",
    "internet": "FM",
    "continent": "Oceania",
    "region": "Pacific",
    "alternate_names": [
      "Federated States of Micronesia"
    ]
  },
  {
    "name": "Moldova",
    "alpha2": "MD",
    "alpha3": "MDA",
    "numeric": "498",
    "locales": [
      "ro-MD",
      "ru-MD"
    ],
    "default_locale": "ro-MD",
    "currency": "MDL",
    "currency_name": "Moldovan Leu",
    "languages": [
      "ro"
    ],
    "capital": "Chișinău",
    "emoji": "🇲🇩",
    "emojiU": "U+1F1F2 U+1F1E9",
    "fips": "MD",
    "internet": "MD",
    "continent": "Europe",
    "region": "Eastern Europe",
    "alternate_names": [
      "Republic of Moldova"
    ]
  },
  {
    "name": "Monaco",
    "alpha2": "MC",
    "alpha3": "MCO",
    "numeric": "492",
    "locales": [
      "fr-MC"
    ],
    "default_locale": "fr-MC",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fr"
    ],
    "capital": "Monaco",
    "emoji": "🇲🇨",
    "emojiU": "U+1F1F2 U+1F1E8",
    "fips": "MN",
    "internet": "MC",
    "continent": "Europe",
    "region": "Western Europe"
  },
  {
    "name": "Mongolia",
    "alpha2": "MN",
    "alpha3": "MNG",
    "numeric": "496",
    "locales": [
      "mn"
    ],
    "default_locale": "mn",
    "currency": "MNT",
    "currency_name": "Tugrik",
    "languages": [
      "mn"
    ],
    "capital": "Ulan Bator",
    "emoji": "🇲🇳",
    "emojiU": "U+1F1F2 U+1F1F3",
    "fips": "MG",
    "internet": "MN",
    "continent": "Asia",
    "region": "Northern Asia"
  },
  {
    "name": "Montenegro",
    "alpha2": "ME",
    "alpha3": "MNE",
    "numeric": "499",
    "locales": [
      "sr-Cyrl-ME",
      "sr-Latn-ME"
    ],
    "default_locale": "sr-Cyrl-ME",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "sr",
      "bs",
      "sq",
      "hr"
    ],
    "capital": "Podgorica",
    "emoji": "🇲🇪",
    "emojiU": "U+1F1F2 U+1F1EA",
    "fips": "MJ",
    "internet": "ME",
    "continent": "Europe",
    "region": "South East Europe"
  },
  {
    "name": "Montserrat",
    "alpha2": "MS",
    "alpha3": "MSR",
    "numeric": "500",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "XCD",
    "currency_name": "East Caribbean Dollar",
    "languages": [
      "en"
    ],
    "capital": "Plymouth",
    "emoji": "🇲🇸",
    "emojiU": "U+1F1F2 U+1F1F8",
    "fips": "MH",
    "internet": "MS",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Morocco",
    "alpha2": "MA",
    "alpha3": "MAR",
    "numeric": "504",
    "locales": [
      "ar-MA",
      "tzm-Latn-MA",
      "shi-Latn-MA",
      "shi-Tfng-MA"
    ],
    "default_locale": "ar-MA",
    "currency": "MAD",
    "currency_name": "Moroccan Dirham",
    "languages": [
      "ar"
    ],
    "capital": "Rabat",
    "emoji": "🇲🇦",
    "emojiU": "U+1F1F2 U+1F1E6",
    "fips": "MO",
    "internet": "MA",
    "continent": "Africa",
    "region": "Northern Africa"
  },
  {
    "name": "Mozambique",
    "alpha2": "MZ",
    "alpha3": "MOZ",
    "numeric": "508",
    "locales": [
      "pt-MZ",
      "seh-MZ"
    ],
    "default_locale": "pt-MZ",
    "currency": "MZN",
    "currency_name": "Mozambique Metical",
    "languages": [
      "pt"
    ],
    "capital": "Maputo",
    "emoji": "🇲🇿",
    "emojiU": "U+1F1F2 U+1F1FF",
    "fips": "MZ",
    "internet": "MZ",
    "continent": "Africa",
    "region": "Southern Africa"
  },
  {
    "name": "Myanmar",
    "alpha2": "MM",
    "alpha3": "MMR",
    "numeric": "104",
    "locales": [
      "my-MM"
    ],
    "default_locale": "my-MM",
    "currency": "MMK",
    "currency_name": "Kyat",
    "languages": [
      "my"
    ],
    "capital": "Naypyidaw",
    "emoji": "🇲🇲",
    "emojiU": "U+1F1F2 U+1F1F2",
    "fips": "BM",
    "internet": "MM",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Namibia",
    "alpha2": "NA",
    "alpha3": "NAM",
    "numeric": "516",
    "locales": [
      "af-NA",
      "en-NA",
      "naq-NA"
    ],
    "default_locale": "en-NA",
    "currency": "ZAR",
    "currency_name": "Rand",
    "languages": [
      "en",
      "af"
    ],
    "capital": "Windhoek",
    "emoji": "🇳🇦",
    "emojiU": "U+1F1F3 U+1F1E6",
    "fips": "WA",
    "internet": "NA",
    "continent": "Africa",
    "region": "Southern Africa"
  },
  {
    "name": "Nauru",
    "alpha2": "NR",
    "alpha3": "NRU",
    "numeric": "520",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "AUD",
    "currency_name": "Australian Dollar",
    "languages": [
      "en",
      "na"
    ],
    "capital": "Yaren",
    "emoji": "🇳🇷",
    "emojiU": "U+1F1F3 U+1F1F7",
    "fips": "NR",
    "internet": "NR",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Nepal",
    "alpha2": "NP",
    "alpha3": "NPL",
    "numeric": "524",
    "locales": [
      "ne-NP"
    ],
    "default_locale": "ne-NP",
    "currency": "NPR",
    "currency_name": "Nepalese Rupee",
    "languages": [
      "ne"
    ],
    "capital": "Kathmandu",
    "emoji": "🇳🇵",
    "emojiU": "U+1F1F3 U+1F1F5",
    "fips": "NP",
    "internet": "NP",
    "continent": "Asia",
    "region": "South Asia"
  },
  {
    "name": "Netherlands",
    "alpha2": "NL",
    "alpha3": "NLD",
    "numeric": "528",
    "locales": [
      "nl-NL"
    ],
    "default_locale": "nl-NL",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "nl"
    ],
    "capital": "Amsterdam",
    "emoji": "🇳🇱",
    "emojiU": "U+1F1F3 U+1F1F1",
    "fips": "NL",
    "internet": "NL",
    "continent": "Europe",
    "region": "Western Europe"
  },
  {
    "name": "Netherlands Antilles",
    "alpha2": "AN",
    "alpha3": "ANT",
    "numeric": "530",
    "locales": [
      "nl-AN"
    ],
    "default_locale": "nl-AN",
    "currency": "ANG",
    "currency_name": "Netherlands Antillean Guilder",
    "fips": "NT",
    "internet": "AN",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "New Caledonia",
    "alpha2": "NC",
    "alpha3": "NCL",
    "numeric": "540",
    "locales": [
      "fr"
    ],
    "default_locale": "fr",
    "currency": "XPF",
    "currency_name": "CFP Franc",
    "languages": [
      "fr"
    ],
    "capital": "Nouméa",
    "emoji": "🇳🇨",
    "emojiU": "U+1F1F3 U+1F1E8",
    "fips": "NC",
    "internet": "NC",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "New Zealand",
    "alpha2": "NZ",
    "alpha3": "NZL",
    "numeric": "554",
    "locales": [
      "en-NZ"
    ],
    "default_locale": "en-NZ",
    "currency": "NZD",
    "currency_name": "New Zealand Dollar",
    "languages": [
      "en",
      "mi"
    ],
    "capital": "Wellington",
    "emoji": "🇳🇿",
    "emojiU": "U+1F1F3 U+1F1FF",
    "fips": "NZ",
    "internet": "NZ",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Nicaragua",
    "alpha2": "NI",
    "alpha3": "NIC",
    "numeric": "558",
    "locales": [
      "es-NI"
    ],
    "default_locale": "es-NI",
    "currency": "NIO",
    "currency_name": "Cordoba Oro",
    "languages": [
      "es"
    ],
    "capital": "Managua",
    "emoji": "🇳🇮",
    "emojiU": "U+1F1F3 U+1F1EE",
    "fips": "NU",
    "internet": "NI",
    "continent": "Americas",
    "region": "Central America"
  },
  {
    "name": "Niger",
    "alpha2": "NE",
    "alpha3": "NER",
    "numeric": "562",
    "locales": [
      "fr-NE",
      "ha-Latn-NE"
    ],
    "default_locale": "fr-NE",
    "currency": "XOF",
    "currency_name": "CFA Franc BCEAO",
    "languages": [
      "fr"
    ],
    "capital": "Niamey",
    "emoji": "🇳🇪",
    "emojiU": "U+1F1F3 U+1F1EA",
    "fips": "NG",
    "internet": "NE",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Nigeria",
    "alpha2": "NG",
    "alpha3": "NGA",
    "numeric": "566",
    "locales": [
      "ha-Latn-NG",
      "ig-NG",
      "yo-NG"
    ],
    "default_locale": "ha-Latn-NG",
    "currency": "NGN",
    "currency_name": "Naira",
    "languages": [
      "en"
    ],
    "capital": "Abuja",
    "emoji": "🇳🇬",
    "emojiU": "U+1F1F3 U+1F1EC",
    "fips": "NI",
    "internet": "NG",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Niue",
    "alpha2": "NU",
    "alpha3": "NIU",
    "numeric": "570",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "NZD",
    "currency_name": "New Zealand Dollar",
    "languages": [
      "en"
    ],
    "capital": "Alofi",
    "emoji": "🇳🇺",
    "emojiU": "U+1F1F3 U+1F1FA",
    "fips": "NE",
    "internet": "NU",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Norfolk Island",
    "alpha2": "NF",
    "alpha3": "NFK",
    "numeric": "574",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "AUD",
    "currency_name": "Australian Dollar",
    "languages": [
      "en"
    ],
    "capital": "Kingston",
    "emoji": "🇳🇫",
    "emojiU": "U+1F1F3 U+1F1EB",
    "fips": "NF",
    "internet": "NF",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "North Macedonia",
    "alpha2": "MK",
    "alpha3": "MKD",
    "numeric": "807",
    "locales": [
      "mk-MK"
    ],
    "default_locale": "mk-MK",
    "currency": "AUD",
    "currency_name": "Australian Dollar",
    "languages": [
      "mk"
    ],
    "capital": "Skopje",
    "emoji": "🇲🇰",
    "emojiU": "U+1F1F2 U+1F1F0",
    "fips": "MK",
    "internet": "MK",
    "continent": "Europe",
    "region": "South East Europe"
  },
  {
    "name": "Northern Mariana Islands",
    "alpha2": "MP",
    "alpha3": "MNP",
    "numeric": "580",
    "locales": [
      "en-MP"
    ],
    "default_locale": "en-MP",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en",
      "ch"
    ],
    "capital": "Saipan",
    "emoji": "🇲🇵",
    "emojiU": "U+1F1F2 U+1F1F5",
    "fips": "CQ",
    "internet": "MP",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Norway",
    "alpha2": "NO",
    "alpha3": "NOR",
    "numeric": "578",
    "locales": [
      "nb-NO",
      "nn-NO"
    ],
    "default_locale": "nb-NO",
    "currency": "NOK",
    "currency_name": "Norwegian Krone",
    "languages": [
      "no",
      "nb",
      "nn"
    ],
    "capital": "Oslo",
    "emoji": "🇳🇴",
    "emojiU": "U+1F1F3 U+1F1F4",
    "fips": "NO",
    "internet": "NO",
    "continent": "Europe",
    "region": "Northern Europe"
  },
  {
    "name": "Oman",
    "alpha2": "OM",
    "alpha3": "OMN",
    "numeric": "512",
    "locales": [
      "ar-OM"
    ],
    "default_locale": "ar-OM",
    "currency": "OMR",
    "currency_name": "Rial Omani",
    "languages": [
      "ar"
    ],
    "capital": "Muscat",
    "emoji": "🇴🇲",
    "emojiU": "U+1F1F4 U+1F1F2",
    "fips": "MU",
    "internet": "OM",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Pakistan",
    "alpha2": "PK",
    "alpha3": "PAK",
    "numeric": "586",
    "locales": [
      "en-PK",
      "pa-Arab-PK",
      "ur-PK"
    ],
    "default_locale": "en-PK",
    "currency": "PKR",
    "currency_name": "Pakistan Rupee",
    "languages": [
      "en",
      "ur"
    ],
    "capital": "Islamabad",
    "emoji": "🇵🇰",
    "emojiU": "U+1F1F5 U+1F1F0",
    "fips": "PK",
    "internet": "PK",
    "continent": "Asia",
    "region": "South Asia"
  },
  {
    "name": "Palau",
    "alpha2": "PW",
    "alpha3": "PLW",
    "numeric": "585",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en"
    ],
    "capital": "Ngerulmud",
    "emoji": "🇵🇼",
    "emojiU": "U+1F1F5 U+1F1FC",
    "fips": "PS",
    "internet": "PW",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Palestine",
    "alpha2": "PS",
    "alpha3": "PSE",
    "numeric": "275",
    "locales": [
      "ar"
    ],
    "default_locale": "ar",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "ar"
    ],
    "capital": "Ramallah",
    "emoji": "🇵🇸",
    "emojiU": "U+1F1F5 U+1F1F8",
    "fips": "WE",
    "internet": "PS",
    "continent": "Asia",
    "region": "South West Asia",
    "alternate_names": [
      "State of Palestine"
    ]
  },
  {
    "name": "Panama",
    "alpha2": "PA",
    "alpha3": "PAN",
    "numeric": "591",
    "locales": [
      "es-PA"
    ],
    "default_locale": "es-PA",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "es"
    ],
    "capital": "Panama City",
    "emoji": "🇵🇦",
    "emojiU": "U+1F1F5 U+1F1E6",
    "fips": "PM",
    "internet": "PA",
    "continent": "Americas",
    "region": "Central America"
  },
  {
    "name": "Papua New Guinea",
    "alpha2": "PG",
    "alpha3": "PNG",
    "numeric": "598",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "PGK",
    "currency_name": "Kina",
    "languages": [
      "en"
    ],
    "capital": "Port Moresby",
    "emoji": "🇵🇬",
    "emojiU": "U+1F1F5 U+1F1EC",
    "fips": "PP",
    "internet": "PG",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Paraguay",
    "alpha2": "PY",
    "alpha3": "PRY",
    "numeric": "600",
    "locales": [
      "es-PY"
    ],
    "default_locale": "es-PY",
    "currency": "PYG",
    "currency_name": "Guarani",
    "languages": [
      "es",
      "gn"
    ],
    "capital": "Asunción",
    "emoji": "🇵🇾",
    "emojiU": "U+1F1F5 U+1F1FE",
    "fips": "PA",
    "internet": "PY",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "Peru",
    "alpha2": "PE",
    "alpha3": "PER",
    "numeric": "604",
    "locales": [
      "es-PE"
    ],
    "default_locale": "es-PE",
    "currency": "PEN",
    "currency_name": "Sol",
    "languages": [
      "es"
    ],
    "capital": "Lima",
    "emoji": "🇵🇪",
    "emojiU": "U+1F1F5 U+1F1EA",
    "fips": "PE",
    "internet": "PE",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "Philippines",
    "alpha2": "PH",
    "alpha3": "PHL",
    "numeric": "608",
    "locales": [
      "en-PH",
      "fil-PH"
    ],
    "default_locale": "en-PH",
    "currency": "PHP",
    "currency_name": "Philippine Peso",
    "languages": [
      "en"
    ],
    "capital": "Manila",
    "emoji": "🇵🇭",
    "emojiU": "U+1F1F5 U+1F1ED",
    "fips": "RP",
    "internet": "PH",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Pitcairn",
    "alpha2": "PN",
    "alpha3": "PCN",
    "numeric": "612",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "NZD",
    "currency_name": "New Zealand Dollar",
    "languages": [
      "en"
    ],
    "capital": "Adamstown",
    "emoji": "🇵🇳",
    "emojiU": "U+1F1F5 U+1F1F3",
    "fips": "PC",
    "internet": "PN",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Poland",
    "alpha2": "PL",
    "alpha3": "POL",
    "numeric": "616",
    "locales": [
      "pl-PL"
    ],
    "default_locale": "pl-PL",
    "currency": "PLN",
    "currency_name": "Zloty",
    "languages": [
      "pl"
    ],
    "capital": "Warsaw",
    "emoji": "🇵🇱",
    "emojiU": "U+1F1F5 U+1F1F1",
    "fips": "PL",
    "internet": "PL",
    "continent": "Europe",
    "region": "Eastern Europe"
  },
  {
    "name": "Portugal",
    "alpha2": "PT",
    "alpha3": "PRT",
    "numeric": "620",
    "locales": [
      "pt-PT"
    ],
    "default_locale": "pt-PT",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "pt"
    ],
    "capital": "Lisbon",
    "emoji": "🇵🇹",
    "emojiU": "U+1F1F5 U+1F1F9",
    "fips": "PO",
    "internet": "PT",
    "continent": "Europe",
    "region": "South West Europe"
  },
  {
    "name": "Puerto Rico",
    "alpha2": "PR",
    "alpha3": "PRI",
    "numeric": "630",
    "locales": [
      "es-PR"
    ],
    "default_locale": "es-PR",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "es",
      "en"
    ],
    "capital": "San Juan",
    "emoji": "🇵🇷",
    "emojiU": "U+1F1F5 U+1F1F7",
    "fips": "RQ",
    "internet": "PR",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Qatar",
    "alpha2": "QA",
    "alpha3": "QAT",
    "numeric": "634",
    "locales": [
      "ar-QA"
    ],
    "default_locale": "ar-QA",
    "currency": "QAR",
    "currency_name": "Qatari Rial",
    "languages": [
      "ar"
    ],
    "capital": "Doha",
    "emoji": "🇶🇦",
    "emojiU": "U+1F1F6 U+1F1E6",
    "fips": "QA",
    "internet": "QA",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Romania",
    "alpha2": "RO",
    "alpha3": "ROU",
    "numeric": "642",
    "locales": [
      "ro-RO"
    ],
    "default_locale": "ro-RO",
    "currency": "RON",
    "currency_name": "Romanian Leu",
    "languages": [
      "ro"
    ],
    "capital": "Bucharest",
    "emoji": "🇷🇴",
    "emojiU": "U+1F1F7 U+1F1F4",
    "fips": "RO",
    "internet": "RO",
    "continent": "Europe",
    "region": "South East Europe"
  },
  {
    "name": "Russia",
    "alpha2": "RU",
    "alpha3": "RUS",
    "numeric": "643",
    "locales": [
      "ru-RU"
    ],
    "default_locale": "ru-RU",
    "currency": "RUB",
    "currency_name": "Russian Ruble",
    "languages": [
      "ru"
    ],
    "capital": "Moscow",
    "emoji": "🇷🇺",
    "emojiU": "U+1F1F7 U+1F1FA",
    "fips": "RS",
    "internet": "RU",
    "continent": "Asia",
    "region": "Northern Asia",
    "alternate_names": [
      "Russian Federation"
    ]
  },
  {
    "name": "Rwanda",
    "alpha2": "RW",
    "alpha3": "RWA",
    "numeric": "646",
    "locales": [
      "fr-RW",
      "rw-RW"
    ],
    "default_locale": "fr-RW",
    "currency": "RWF",
    "currency_name": "Rwanda Franc",
    "languages": [
      "rw",
      "en",
      "fr"
    ],
    "capital": "Kigali",
    "emoji": "🇷🇼",
    "emojiU": "U+1F1F7 U+1F1FC",
    "fips": "RW",
    "internet": "RW",
    "continent": "Africa",
    "region": "Central Africa"
  },
  {
    "name": "Réunion",
    "alpha2": "RE",
    "alpha3": "REU",
    "numeric": "638",
    "locales": [
      "fr-RE"
    ],
    "default_locale": "fr-RE",
    "currency": "RWF",
    "currency_name": "Rwanda Franc",
    "languages": [
      "fr"
    ],
    "capital": "Saint-Denis",
    "emoji": "🇷🇪",
    "emojiU": "U+1F1F7 U+1F1EA",
    "fips": "RE",
    "internet": "RE",
    "continent": "Africa",
    "region": "Indian Ocean"
  },
  {
    "name": "Saint Barthélemy",
    "alpha2": "BL",
    "alpha3": "BLM",
    "numeric": "652",
    "locales": [
      "fr-BL"
    ],
    "default_locale": "fr-BL",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fr"
    ],
    "capital": "Gustavia",
    "emoji": "🇧🇱",
    "emojiU": "U+1F1E7 U+1F1F1",
    "fips": "TB",
    "internet": "BL",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Saint Helena",
    "alpha2": "SH",
    "alpha3": "SHN",
    "numeric": "654",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "SHP",
    "currency_name": "Saint Helena Pound",
    "languages": [
      "en"
    ],
    "capital": "Jamestown",
    "emoji": "🇸🇭",
    "emojiU": "U+1F1F8 U+1F1ED",
    "fips": "SH",
    "internet": "SH",
    "continent": "Atlantic Ocean",
    "region": "South Atlantic Ocean",
    "alternate_names": [
      "Saint Helena, Ascension and Tristan da Cunha"
    ]
  },
  {
    "name": "Saint Kitts and Nevis",
    "alpha2": "KN",
    "alpha3": "KNA",
    "numeric": "659",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "XCD",
    "currency_name": "East Caribbean Dollar",
    "languages": [
      "en"
    ],
    "capital": "Basseterre",
    "emoji": "🇰🇳",
    "emojiU": "U+1F1F0 U+1F1F3",
    "fips": "SC",
    "internet": "KN",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Saint Lucia",
    "alpha2": "LC",
    "alpha3": "LCA",
    "numeric": "662",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "XCD",
    "currency_name": "East Caribbean Dollar",
    "languages": [
      "en"
    ],
    "capital": "Castries",
    "emoji": "🇱🇨",
    "emojiU": "U+1F1F1 U+1F1E8",
    "fips": "ST",
    "internet": "LC",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Saint Martin",
    "alpha2": "MF",
    "alpha3": "MAF",
    "numeric": "663",
    "locales": [
      "fr-MF"
    ],
    "default_locale": "fr-MF",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "en",
      "fr",
      "nl"
    ],
    "capital": "Marigot",
    "emoji": "🇲🇫",
    "emojiU": "U+1F1F2 U+1F1EB",
    "fips": "RN",
    "internet": "MF",
    "continent": "Americas",
    "region": "West Indies",
    "alternate_names": [
      "Saint Martin French part"
    ]
  },
  {
    "name": "Saint Pierre and Miquelon",
    "alpha2": "PM",
    "alpha3": "SPM",
    "numeric": "666",
    "locales": [
      "fr"
    ],
    "default_locale": "fr",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "fr"
    ],
    "capital": "Saint-Pierre",
    "emoji": "🇵🇲",
    "emojiU": "U+1F1F5 U+1F1F2",
    "fips": "SB",
    "internet": "PM",
    "continent": "Americas",
    "region": "North America"
  },
  {
    "name": "Saint Vincent and the Grenadines",
    "alpha2": "VC",
    "alpha3": "VCT",
    "numeric": "670",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "XCD",
    "currency_name": "East Caribbean Dollar",
    "languages": [
      "en"
    ],
    "capital": "Kingstown",
    "emoji": "🇻🇨",
    "emojiU": "U+1F1FB U+1F1E8",
    "fips": "VC",
    "internet": "VC",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Samoa",
    "alpha2": "WS",
    "alpha3": "WSM",
    "numeric": "882",
    "locales": [
      "sm"
    ],
    "default_locale": "sm",
    "currency": "WST",
    "currency_name": "Tala",
    "languages": [
      "sm",
      "en"
    ],
    "capital": "Apia",
    "emoji": "🇼🇸",
    "emojiU": "U+1F1FC U+1F1F8",
    "fips": "WS",
    "internet": "WS",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "San Marino",
    "alpha2": "SM",
    "alpha3": "SMR",
    "numeric": "674",
    "locales": [
      "it"
    ],
    "default_locale": "it",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "it"
    ],
    "capital": "City of San Marino",
    "emoji": "🇸🇲",
    "emojiU": "U+1F1F8 U+1F1F2",
    "fips": "SM",
    "internet": "SM",
    "continent": "Europe",
    "region": "Southern Europe"
  },
  {
    "name": "Sao Tome and Principe",
    "alpha2": "ST",
    "alpha3": "STP",
    "numeric": "678",
    "locales": [
      "pt"
    ],
    "default_locale": "pt",
    "currency": "STN",
    "currency_name": "Dobra",
    "languages": [
      "pt"
    ],
    "capital": "São Tomé",
    "emoji": "🇸🇹",
    "emojiU": "U+1F1F8 U+1F1F9",
    "fips": "TP",
    "internet": "ST",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Saudi Arabia",
    "alpha2": "SA",
    "alpha3": "SAU",
    "numeric": "682",
    "locales": [
      "ar-SA"
    ],
    "default_locale": "ar-SA",
    "currency": "SAR",
    "currency_name": "Saudi Riyal",
    "languages": [
      "ar"
    ],
    "capital": "Riyadh",
    "emoji": "🇸🇦",
    "emojiU": "U+1F1F8 U+1F1E6",
    "fips": "SA",
    "internet": "SA",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Senegal",
    "alpha2": "SN",
    "alpha3": "SEN",
    "numeric": "686",
    "locales": [
      "fr-SN",
      "ff-SN"
    ],
    "default_locale": "fr-SN",
    "currency": "XOF",
    "currency_name": "CFA Franc BCEAO",
    "languages": [
      "fr"
    ],
    "capital": "Dakar",
    "emoji": "🇸🇳",
    "emojiU": "U+1F1F8 U+1F1F3",
    "fips": "SG",
    "internet": "SN",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Serbia",
    "alpha2": "RS",
    "alpha3": "SRB",
    "numeric": "688",
    "locales": [
      "sr-Cyrl-RS",
      "sr-Latn-RS"
    ],
    "default_locale": "sr-Cyrl-RS",
    "currency": "RSD",
    "currency_name": "Serbian Dinar",
    "languages": [
      "sr"
    ],
    "capital": "Belgrade",
    "emoji": "🇷🇸",
    "emojiU": "U+1F1F7 U+1F1F8",
    "fips": "RI",
    "internet": "RS",
    "continent": "Europe",
    "region": "South East Europe"
  },
  {
    "name": "Seychelles",
    "alpha2": "SC",
    "alpha3": "SYC",
    "numeric": "690",
    "locales": [
      "fr"
    ],
    "default_locale": "fr",
    "currency": "SCR",
    "currency_name": "Seychelles Rupee",
    "languages": [
      "fr",
      "en"
    ],
    "capital": "Victoria",
    "emoji": "🇸🇨",
    "emojiU": "U+1F1F8 U+1F1E8",
    "fips": "SE",
    "internet": "SC",
    "continent": "Africa",
    "region": "Indian Ocean"
  },
  {
    "name": "Sierra Leone",
    "alpha2": "SL",
    "alpha3": "SLE",
    "numeric": "694",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "SLL",
    "currency_name": "Leone",
    "languages": [
      "en"
    ],
    "capital": "Freetown",
    "emoji": "🇸🇱",
    "emojiU": "U+1F1F8 U+1F1F1",
    "fips": "SL",
    "internet": "SL",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Singapore",
    "alpha2": "SG",
    "alpha3": "SGP",
    "numeric": "702",
    "locales": [
      "zh-Hans-SG",
      "en-SG"
    ],
    "default_locale": "en-SG",
    "currency": "SGD",
    "currency_name": "Singapore Dollar",
    "languages": [
      "en",
      "ms",
      "ta",
      "zh"
    ],
    "capital": "Singapore",
    "emoji": "🇸🇬",
    "emojiU": "U+1F1F8 U+1F1EC",
    "fips": "SN",
    "internet": "SG",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Sint Maarten",
    "alpha2": "SX",
    "alpha3": "SXM",
    "numeric": "534",
    "locales": [
      "nl"
    ],
    "default_locale": "nl",
    "currency": "ANG",
    "currency_name": "Netherlands Antillean Guilder",
    "languages": [
      "nl",
      "en"
    ],
    "capital": "Philipsburg",
    "emoji": "🇸🇽",
    "emojiU": "U+1F1F8 U+1F1FD",
    "fips": "NN",
    "internet": "SX",
    "continent": "Americas",
    "region": "West Indies",
    "alternate_names": [
      "Sint Maarten Dutch part"
    ]
  },
  {
    "name": "Slovakia",
    "alpha2": "SK",
    "alpha3": "SVK",
    "numeric": "703",
    "locales": [
      "sk-SK"
    ],
    "default_locale": "sk-SK",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "sk"
    ],
    "capital": "Bratislava",
    "emoji": "🇸🇰",
    "emojiU": "U+1F1F8 U+1F1F0",
    "fips": "LO",
    "internet": "SK",
    "continent": "Europe",
    "region": "Central Europe"
  },
  {
    "name": "Slovenia",
    "alpha2": "SI",
    "alpha3": "SVN",
    "numeric": "705",
    "locales": [
      "sl-SI"
    ],
    "default_locale": "sl-SI",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "sl"
    ],
    "capital": "Ljubljana",
    "emoji": "🇸🇮",
    "emojiU": "U+1F1F8 U+1F1EE",
    "fips": "SI",
    "internet": "SI",
    "continent": "Europe",
    "region": "South East Europe"
  },
  {
    "name": "Solomon Islands",
    "alpha2": "SB",
    "alpha3": "SLB",
    "numeric": "090",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "SBD",
    "currency_name": "Solomon Islands Dollar",
    "languages": [
      "en"
    ],
    "capital": "Honiara",
    "emoji": "🇸🇧",
    "emojiU": "U+1F1F8 U+1F1E7",
    "fips": "BP",
    "internet": "SB",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Somalia",
    "alpha2": "SO",
    "alpha3": "SOM",
    "numeric": "706",
    "locales": [
      "so-SO"
    ],
    "default_locale": "so-SO",
    "currency": "SOS",
    "currency_name": "Somali Shilling",
    "languages": [
      "so",
      "ar"
    ],
    "capital": "Mogadishu",
    "emoji": "🇸🇴",
    "emojiU": "U+1F1F8 U+1F1F4",
    "fips": "SO",
    "internet": "SO",
    "continent": "Africa",
    "region": "Eastern Africa"
  },
  {
    "name": "South Africa",
    "alpha2": "ZA",
    "alpha3": "ZAF",
    "numeric": "710",
    "locales": [
      "af-ZA",
      "en-ZA",
      "zu-ZA"
    ],
    "default_locale": "af-ZA",
    "currency": "ZAR",
    "currency_name": "Rand",
    "languages": [
      "af",
      "en",
      "nr",
      "st",
      "ss",
      "tn",
      "ts",
      "ve",
      "xh",
      "zu"
    ],
    "capital": "Pretoria",
    "emoji": "🇿🇦",
    "emojiU": "U+1F1FF U+1F1E6",
    "fips": "SF",
    "internet": "ZA",
    "continent": "Africa",
    "region": "Southern Africa"
  },
  {
    "name": "South Georgia and the South Sandwich Islands",
    "alpha2": "GS",
    "alpha3": "SGS",
    "numeric": "239",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en"
    ],
    "capital": "King Edward Point",
    "emoji": "🇬🇸",
    "emojiU": "U+1F1EC U+1F1F8",
    "fips": "SX",
    "internet": "GS",
    "continent": "Atlantic Ocean",
    "region": "South Atlantic Ocean"
  },
  {
    "name": "South Sudan",
    "alpha2": "SS",
    "alpha3": "SSD",
    "numeric": "728",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "SSP",
    "currency_name": "South Sudanese Pound",
    "languages": [
      "en"
    ],
    "capital": "Juba",
    "emoji": "🇸🇸",
    "emojiU": "U+1F1F8 U+1F1F8",
    "fips": "OD",
    "internet": "SS",
    "continent": "Africa",
    "region": "Northern Africa"
  },
  {
    "name": "Spain",
    "alpha2": "ES",
    "alpha3": "ESP",
    "numeric": "724",
    "locales": [
      "eu-ES",
      "ca-ES",
      "gl-ES",
      "es-ES"
    ],
    "default_locale": "es-ES",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "es",
      "eu",
      "ca",
      "gl",
      "oc"
    ],
    "capital": "Madrid",
    "emoji": "🇪🇸",
    "emojiU": "U+1F1EA U+1F1F8",
    "fips": "SP",
    "internet": "ES",
    "continent": "Europe",
    "region": "South West Europe"
  },
  {
    "name": "Sri Lanka",
    "alpha2": "LK",
    "alpha3": "LKA",
    "numeric": "144",
    "locales": [
      "si-LK",
      "ta-LK"
    ],
    "default_locale": "si-LK",
    "currency": "LKR",
    "currency_name": "Sri Lanka Rupee",
    "languages": [
      "si",
      "ta"
    ],
    "capital": "Colombo",
    "emoji": "🇱🇰",
    "emojiU": "U+1F1F1 U+1F1F0",
    "fips": "CE",
    "internet": "LK",
    "continent": "Asia",
    "region": "South Asia"
  },
  {
    "name": "Sudan",
    "alpha2": "SD",
    "alpha3": "SDN",
    "numeric": "729",
    "locales": [
      "ar-SD"
    ],
    "default_locale": "ar-SD",
    "currency": "SDG",
    "currency_name": "Sudanese Pound",
    "languages": [
      "ar",
      "en"
    ],
    "capital": "Khartoum",
    "emoji": "🇸🇩",
    "emojiU": "U+1F1F8 U+1F1E9",
    "fips": "SU",
    "internet": "SD",
    "continent": "Africa",
    "region": "Northern Africa"
  },
  {
    "name": "Suriname",
    "alpha2": "SR",
    "alpha3": "SUR",
    "numeric": "740",
    "locales": [
      "nl"
    ],
    "default_locale": "nl",
    "currency": "SRD",
    "currency_name": "Surinam Dollar",
    "languages": [
      "nl"
    ],
    "capital": "Paramaribo",
    "emoji": "🇸🇷",
    "emojiU": "U+1F1F8 U+1F1F7",
    "fips": "NS",
    "internet": "SR",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "Svalbard and Jan Mayen",
    "alpha2": "SJ",
    "alpha3": "SJM",
    "numeric": "744",
    "locales": [
      "no"
    ],
    "default_locale": "no",
    "currency": "NOK",
    "currency_name": "Norwegian Krone",
    "languages": [
      "no"
    ],
    "capital": "Longyearbyen",
    "emoji": "🇸🇯",
    "emojiU": "U+1F1F8 U+1F1EF",
    "fips": "SV",
    "internet": "SJ",
    "continent": "Europe",
    "region": "Northern Europe"
  },
  {
    "name": "Sweden",
    "alpha2": "SE",
    "alpha3": "SWE",
    "numeric": "752",
    "locales": [
      "sv-SE"
    ],
    "default_locale": "sv-SE",
    "currency": "SEK",
    "currency_name": "Swedish Krona",
    "languages": [
      "sv"
    ],
    "capital": "Stockholm",
    "emoji": "🇸🇪",
    "emojiU": "U+1F1F8 U+1F1EA",
    "fips": "SW",
    "internet": "SE",
    "continent": "Europe",
    "region": "Northern Europe"
  },
  {
    "name": "Switzerland",
    "alpha2": "CH",
    "alpha3": "CHE",
    "numeric": "756",
    "locales": [
      "fr-CH",
      "de-CH",
      "it-CH",
      "rm-CH",
      "gsw-CH"
    ],
    "default_locale": "fr-CH",
    "currency": "CHW",
    "currency_name": "WIR Franc",
    "languages": [
      "de",
      "fr",
      "it"
    ],
    "capital": "Bern",
    "emoji": "🇨🇭",
    "emojiU": "U+1F1E8 U+1F1ED",
    "fips": "SZ",
    "internet": "CH",
    "continent": "Europe",
    "region": "Central Europe"
  },
  {
    "name": "Syrian Arab Republic",
    "alpha2": "SY",
    "alpha3": "SYR",
    "numeric": "760",
    "locales": [
      "ar-SY"
    ],
    "default_locale": "ar-SY",
    "currency": "SYP",
    "currency_name": "Syrian Pound",
    "languages": [
      "ar"
    ],
    "capital": "Damascus",
    "emoji": "🇸🇾",
    "emojiU": "U+1F1F8 U+1F1FE",
    "fips": "SY",
    "internet": "SY",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Taiwan",
    "alpha2": "TW",
    "alpha3": "TWN",
    "numeric": "158",
    "locales": [
      "zh-Hant-TW"
    ],
    "default_locale": "zh-Hant-TW",
    "currency": "TWD",
    "currency_name": "New Taiwan Dollar",
    "languages": [
      "zh"
    ],
    "capital": "Taipei",
    "emoji": "🇹🇼",
    "emojiU": "U+1F1F9 U+1F1FC",
    "fips": "TW",
    "internet": "TW",
    "continent": "Asia",
    "region": "East Asia",
    "alternate_names": [
      "Province of China Taiwan"
    ]
  },
  {
    "name": "Tajikistan",
    "alpha2": "TJ",
    "alpha3": "TJK",
    "numeric": "762",
    "locales": [
      "tg"
    ],
    "default_locale": "tg",
    "currency": "TJS",
    "currency_name": "Somoni",
    "languages": [
      "tg",
      "ru"
    ],
    "capital": "Dushanbe",
    "emoji": "🇹🇯",
    "emojiU": "U+1F1F9 U+1F1EF",
    "fips": "TI",
    "internet": "TJ",
    "continent": "Asia",
    "region": "Central Asia"
  },
  {
    "name": "Tanzania",
    "alpha2": "TZ",
    "alpha3": "TZA",
    "numeric": "834",
    "locales": [
      "asa-TZ",
      "bez-TZ",
      "lag-TZ",
      "jmc-TZ",
      "kde-TZ",
      "mas-TZ",
      "rof-TZ",
      "rwk-TZ",
      "sw-TZ",
      "vun-TZ"
    ],
    "default_locale": "asa-TZ",
    "currency": "TZS",
    "currency_name": "Tanzanian Shilling",
    "languages": [
      "sw",
      "en"
    ],
    "capital": "Dodoma",
    "emoji": "🇹🇿",
    "emojiU": "U+1F1F9 U+1F1FF",
    "fips": "TZ",
    "internet": "TZ",
    "continent": "Africa",
    "region": "Eastern Africa",
    "alternate_names": [
      "United Republic of Tanzania"
    ]
  },
  {
    "name": "Thailand",
    "alpha2": "TH",
    "alpha3": "THA",
    "numeric": "764",
    "locales": [
      "th-TH"
    ],
    "default_locale": "th-TH",
    "currency": "THB",
    "currency_name": "Baht",
    "languages": [
      "th"
    ],
    "capital": "Bangkok",
    "emoji": "🇹🇭",
    "emojiU": "U+1F1F9 U+1F1ED",
    "fips": "TH",
    "internet": "TH",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Timor-Leste",
    "alpha2": "TL",
    "alpha3": "TLS",
    "numeric": "626",
    "locales": [
      "pt"
    ],
    "default_locale": "pt",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "pt"
    ],
    "capital": "Dili",
    "emoji": "🇹🇱",
    "emojiU": "U+1F1F9 U+1F1F1",
    "fips": "TT",
    "internet": "TL",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Togo",
    "alpha2": "TG",
    "alpha3": "TGO",
    "numeric": "768",
    "locales": [
      "ee-TG",
      "fr-TG"
    ],
    "default_locale": "fr-TG",
    "currency": "XOF",
    "currency_name": "CFA Franc BCEAO",
    "languages": [
      "fr"
    ],
    "capital": "Lomé",
    "emoji": "🇹🇬",
    "emojiU": "U+1F1F9 U+1F1EC",
    "fips": "TO",
    "internet": "TG",
    "continent": "Africa",
    "region": "Western Africa"
  },
  {
    "name": "Tokelau",
    "alpha2": "TK",
    "alpha3": "TKL",
    "numeric": "772",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "NZD",
    "currency_name": "New Zealand Dollar",
    "languages": [
      "en"
    ],
    "capital": "Fakaofo",
    "emoji": "🇹🇰",
    "emojiU": "U+1F1F9 U+1F1F0",
    "fips": "TL",
    "internet": "TK",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Tonga",
    "alpha2": "TO",
    "alpha3": "TON",
    "numeric": "776",
    "locales": [
      "to-TO"
    ],
    "default_locale": "to-TO",
    "currency": "TOP",
    "currency_name": "Pa’anga",
    "languages": [
      "en",
      "to"
    ],
    "capital": "Nuku'alofa",
    "emoji": "🇹🇴",
    "emojiU": "U+1F1F9 U+1F1F4",
    "fips": "TN",
    "internet": "TO",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Trinidad and Tobago",
    "alpha2": "TT",
    "alpha3": "TTO",
    "numeric": "780",
    "locales": [
      "en-TT"
    ],
    "default_locale": "en-TT",
    "currency": "TTD",
    "currency_name": "Trinidad and Tobago Dollar",
    "languages": [
      "en"
    ],
    "capital": "Port of Spain",
    "emoji": "🇹🇹",
    "emojiU": "U+1F1F9 U+1F1F9",
    "fips": "TD",
    "internet": "TT",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Tunisia",
    "alpha2": "TN",
    "alpha3": "TUN",
    "numeric": "788",
    "locales": [
      "ar-TN"
    ],
    "default_locale": "ar-TN",
    "currency": "TND",
    "currency_name": "Tunisian Dinar",
    "languages": [
      "ar"
    ],
    "capital": "Tunis",
    "emoji": "🇹🇳",
    "emojiU": "U+1F1F9 U+1F1F3",
    "fips": "TS",
    "internet": "TN",
    "continent": "Africa",
    "region": "Northern Africa"
  },
  {
    "name": "Turkey",
    "alpha2": "TR",
    "alpha3": "TUR",
    "numeric": "792",
    "locales": [
      "tr-TR"
    ],
    "default_locale": "tr-TR",
    "currency": "TRY",
    "currency_name": "Turkish Lira",
    "languages": [
      "tr"
    ],
    "capital": "Ankara",
    "emoji": "🇹🇷",
    "emojiU": "U+1F1F9 U+1F1F7",
    "fips": "TU",
    "internet": "TR",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Turkmenistan",
    "alpha2": "TM",
    "alpha3": "TKM",
    "numeric": "795",
    "locales": [
      "tk"
    ],
    "default_locale": "tk",
    "currency": "TMT",
    "currency_name": "Turkmenistan New Manat",
    "languages": [
      "tk",
      "ru"
    ],
    "capital": "Ashgabat",
    "emoji": "🇹🇲",
    "emojiU": "U+1F1F9 U+1F1F2",
    "fips": "TX",
    "internet": "TM",
    "continent": "Asia",
    "region": "Central Asia"
  },
  {
    "name": "Turks and Caicos Islands",
    "alpha2": "TC",
    "alpha3": "TCA",
    "numeric": "796",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en"
    ],
    "capital": "Cockburn Town",
    "emoji": "🇹🇨",
    "emojiU": "U+1F1F9 U+1F1E8",
    "fips": "TK",
    "internet": "TC",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Tuvalu",
    "alpha2": "TV",
    "alpha3": "TUV",
    "numeric": "798",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "AUD",
    "currency_name": "Australian Dollar",
    "languages": [
      "en"
    ],
    "capital": "Funafuti",
    "emoji": "🇹🇻",
    "emojiU": "U+1F1F9 U+1F1FB",
    "fips": "TV",
    "internet": "TV",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Uganda",
    "alpha2": "UG",
    "alpha3": "UGA",
    "numeric": "800",
    "locales": [
      "cgg-UG",
      "lg-UG",
      "nyn-UG",
      "xog-UG",
      "teo-UG"
    ],
    "default_locale": "cgg-UG",
    "currency": "UGX",
    "currency_name": "Uganda Shilling",
    "languages": [
      "en",
      "sw"
    ],
    "capital": "Kampala",
    "emoji": "🇺🇬",
    "emojiU": "U+1F1FA U+1F1EC",
    "fips": "UG",
    "internet": "UG",
    "continent": "Africa",
    "region": "Eastern Africa"
  },
  {
    "name": "Ukraine",
    "alpha2": "UA",
    "alpha3": "UKR",
    "numeric": "804",
    "locales": [
      "ru-UA",
      "uk-UA"
    ],
    "default_locale": "uk-UA",
    "currency": "UAH",
    "currency_name": "Hryvnia",
    "languages": [
      "uk"
    ],
    "capital": "Kyiv",
    "emoji": "🇺🇦",
    "emojiU": "U+1F1FA U+1F1E6",
    "fips": "UP",
    "internet": "UA",
    "continent": "Europe",
    "region": "Eastern Europe"
  },
  {
    "name": "United Arab Emirates",
    "alpha2": "AE",
    "alpha3": "ARE",
    "numeric": "784",
    "locales": [
      "ar-AE"
    ],
    "default_locale": "ar-AE",
    "currency": "AED",
    "currency_name": "UAE Dirham",
    "languages": [
      "ar"
    ],
    "capital": "Abu Dhabi",
    "emoji": "🇦🇪",
    "emojiU": "U+1F1E6 U+1F1EA",
    "fips": "TC",
    "internet": "AE",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "United Kingdom",
    "alpha2": "GB",
    "alpha3": "GBR",
    "numeric": "826",
    "locales": [
      "kw-GB",
      "en-GB",
      "gv-GB",
      "cy-GB"
    ],
    "default_locale": "en-GB",
    "currency": "GBP",
    "currency_name": "Pound Sterling",
    "languages": [
      "en"
    ],
    "capital": "London",
    "emoji": "🇬🇧",
    "emojiU": "U+1F1EC U+1F1E7",
    "fips": "UK",
    "internet": "UK",
    "continent": "Europe",
    "region": "Western Europe",
    "alternate_names": [
      "United Kingdom of Great Britain and Northern Ireland"
    ]
  },
  {
    "name": "United States Minor Outlying Islands",
    "alpha2": "UM",
    "alpha3": "UMI",
    "numeric": "581",
    "locales": [
      "en-UM"
    ],
    "default_locale": "en-UM",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en"
    ],
    "capital": "",
    "emoji": "🇺🇲",
    "emojiU": "U+1F1FA U+1F1F2",
    "fips": "",
    "internet": "US",
    "continent": "Americas",
    "region": "North America"
  },
  {
    "name": "United States",
    "alpha2": "US",
    "alpha3": "USA",
    "numeric": "840",
    "locales": [
      "chr-US",
      "en-US",
      "haw-US",
      "es-US"
    ],
    "default_locale": "en-US",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en"
    ],
    "capital": "Washington D.C.",
    "emoji": "🇺🇸",
    "emojiU": "U+1F1FA U+1F1F8",
    "fips": "US",
    "internet": "US",
    "continent": "Americas",
    "region": "North America",
    "alternate_names": [
      "United States of America"
    ]
  },
  {
    "name": "Uruguay",
    "alpha2": "UY",
    "alpha3": "URY",
    "numeric": "858",
    "locales": [
      "es-UY"
    ],
    "default_locale": "es-UY",
    "currency": "UYU",
    "currency_name": "Peso Uruguayo",
    "languages": [
      "es"
    ],
    "capital": "Montevideo",
    "emoji": "🇺🇾",
    "emojiU": "U+1F1FA U+1F1FE",
    "fips": "UY",
    "internet": "UY",
    "continent": "Americas",
    "region": "South America"
  },
  {
    "name": "Uzbekistan",
    "alpha2": "UZ",
    "alpha3": "UZB",
    "numeric": "860",
    "locales": [
      "uz-Cyrl-UZ",
      "uz-Latn-UZ"
    ],
    "default_locale": "uz-Cyrl-UZ",
    "currency": "UZS",
    "currency_name": "Uzbekistan Sum",
    "languages": [
      "uz",
      "ru"
    ],
    "capital": "Tashkent",
    "emoji": "🇺🇿",
    "emojiU": "U+1F1FA U+1F1FF",
    "fips": "UZ",
    "internet": "UZ",
    "continent": "Asia",
    "region": "Central Asia"
  },
  {
    "name": "Vanuatu",
    "alpha2": "VU",
    "alpha3": "VUT",
    "numeric": "548",
    "locales": [
      "bi"
    ],
    "default_locale": "bi",
    "currency": "VUV",
    "currency_name": "Vatu",
    "languages": [
      "bi",
      "en",
      "fr"
    ],
    "capital": "Port Vila",
    "emoji": "🇻🇺",
    "emojiU": "U+1F1FB U+1F1FA",
    "fips": "NH",
    "internet": "VU",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Venezuela",
    "alpha2": "VE",
    "alpha3": "VEN",
    "numeric": "862",
    "locales": [
      "es-VE"
    ],
    "default_locale": "es-VE",
    "currency": "VUV",
    "currency_name": "Vatu",
    "languages": [
      "es"
    ],
    "capital": "Caracas",
    "emoji": "🇻🇪",
    "emojiU": "U+1F1FB U+1F1EA",
    "fips": "VE",
    "internet": "UE",
    "continent": "Americas",
    "region": "South America",
    "alternate_names": [
      "Bolivarian Republic of Venezuela"
    ]
  },
  {
    "name": "Viet Nam",
    "alpha2": "VN",
    "alpha3": "VNM",
    "numeric": "704",
    "locales": [
      "vi-VN"
    ],
    "default_locale": "vi-VN",
    "currency": "VND",
    "currency_name": "Dong",
    "languages": [
      "vi"
    ],
    "capital": "Hanoi",
    "emoji": "🇻🇳",
    "emojiU": "U+1F1FB U+1F1F3",
    "fips": "VN",
    "internet": "VN",
    "continent": "Asia",
    "region": "South East Asia"
  },
  {
    "name": "Virgin Islands (British)",
    "alpha2": "VG",
    "alpha3": "VGB",
    "numeric": "092",
    "locales": [
      "en"
    ],
    "default_locale": "en",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en"
    ],
    "capital": "Road Town",
    "emoji": "🇻🇬",
    "emojiU": "U+1F1FB U+1F1EC",
    "fips": "VI",
    "internet": "VG",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Virgin Islands (U.S.)",
    "alpha2": "VI",
    "alpha3": "VIR",
    "numeric": "850",
    "locales": [
      "en-VI"
    ],
    "default_locale": "en-VI",
    "currency": "USD",
    "currency_name": "US Dollar",
    "languages": [
      "en"
    ],
    "capital": "Charlotte Amalie",
    "emoji": "🇻🇮",
    "emojiU": "U+1F1FB U+1F1EE",
    "fips": "VQ",
    "internet": "VI",
    "continent": "Americas",
    "region": "West Indies"
  },
  {
    "name": "Wallis and Futuna",
    "alpha2": "WF",
    "alpha3": "WLF",
    "numeric": "876",
    "locales": [
      "fr"
    ],
    "default_locale": "fr",
    "currency": "XPF",
    "currency_name": "CFP Franc",
    "languages": [
      "fr"
    ],
    "capital": "Mata-Utu",
    "emoji": "🇼🇫",
    "emojiU": "U+1F1FC U+1F1EB",
    "fips": "WF",
    "internet": "WF",
    "continent": "Oceania",
    "region": "Pacific"
  },
  {
    "name": "Western Sahara",
    "alpha2": "EH",
    "alpha3": "ESH",
    "numeric": "732",
    "locales": [
      "es"
    ],
    "default_locale": "es",
    "currency": "MAD",
    "currency_name": "Moroccan Dirham",
    "languages": [
      "es"
    ],
    "capital": "El Aaiún",
    "emoji": "🇪🇭",
    "emojiU": "U+1F1EA U+1F1ED",
    "fips": "WI",
    "internet": "EH",
    "continent": "Africa",
    "region": "Northern Africa"
  },
  {
    "name": "Yemen",
    "alpha2": "YE",
    "alpha3": "YEM",
    "numeric": "887",
    "locales": [
      "ar-YE"
    ],
    "default_locale": "ar-YE",
    "currency": "YER",
    "currency_name": "Yemeni Rial",
    "languages": [
      "ar"
    ],
    "capital": "Sana'a",
    "emoji": "🇾🇪",
    "emojiU": "U+1F1FE U+1F1EA",
    "fips": "YM",
    "internet": "YE",
    "continent": "Asia",
    "region": "South West Asia"
  },
  {
    "name": "Zambia",
    "alpha2": "ZM",
    "alpha3": "ZMB",
    "numeric": "894",
    "locales": [
      "bem-ZM"
    ],
    "default_locale": "bem-ZM",
    "currency": "ZMW",
    "currency_name": "Zambian Kwacha",
    "languages": [
      "en"
    ],
    "capital": "Lusaka",
    "emoji": "🇿🇲",
    "emojiU": "U+1F1FF U+1F1F2",
    "fips": "ZA",
    "internet": "ZM",
    "continent": "Africa",
    "region": "Southern Africa"
  },
  {
    "name": "Zimbabwe",
    "alpha2": "ZW",
    "alpha3": "ZWE",
    "numeric": "716",
    "locales": [
      "en-ZW",
      "nd-ZW",
      "sn-ZW"
    ],
    "default_locale": "en-ZW",
    "currency": "ZWL",
    "currency_name": "Zimbabwe Dollar",
    "languages": [
      "en",
      "sn",
      "nd"
    ],
    "capital": "Harare",
    "emoji": "🇿🇼",
    "emojiU": "U+1F1FF U+1F1FC",
    "fips": "ZI",
    "internet": "ZW",
    "continent": "Africa",
    "region": "Southern Africa"
  },
  {
    "name": "Åland Islands",
    "alpha2": "AX",
    "alpha3": "ALA",
    "numeric": "248",
    "locales": [
      "sv"
    ],
    "default_locale": "sv",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "sv"
    ],
    "capital": "Mariehamn",
    "emoji": "🇦🇽",
    "emojiU": "U+1F1E6 U+1F1FD",
    "fips": "AX",
    "internet": "AX",
    "continent": "Europe",
    "region": "Northern Europe"
  },
  {
    "name": "Kosovo",
    "alpha2": "XK",
    "alpha3": "XKX",
    "numeric": "383",
    "locales": [
      "sq"
    ],
    "default_locale": "sq",
    "currency": "EUR",
    "currency_name": "Euro",
    "languages": [
      "sq",
      "sr"
    ],
    "capital": "Pristina",
    "emoji": "🇽🇰",
    "emojiU": "U+1F1FD U+1F1F0"
  }
]


export const countries = 
  locales.map(c => ({
    icon: c.emoji,
    value: c.alpha2,
    label: new Intl.DisplayNames(lang, { type: 'region' }).of(c.alpha2),
  }));

// Features the the browser's Intl API for handling locale
// https://www.bram.us/2020/03/04/esnext-localized-language-currency-and-region-names-with-intl-displaynames/
export const languages = 
  locales.map(c => {
    let langs = c.default_locale.split('-')
    langs = langs.length === 1 ? [...langs, c.alpha2] : langs
    return {
      icon: c.emoji,
      value: langs.join('_').toLowerCase(),
      label: new Intl.DisplayNames(lang, { type: 'language' }).of(c.default_locale),
    }
  });


// Generate countries in the language of your choice.
// Yields KV of country code, name.
export function getCountries(lang) {

  const A = 65
  const Z = 90
  const countryName = new Intl.DisplayNames([lang], { type: 'region' });
  const countries = {}
  for(let i=A; i<=Z; ++i) {
      for(let j=A; j<=Z; ++j) {
          let code = String.fromCharCode(i) + String.fromCharCode(j)
          let name = countryName.of(code)
          if (code !== name) {
              countries[code] = name
          }
      }
  }
  return countries
}