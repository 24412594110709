import { isMatch } from "lodash";
import { createSelector } from 'reselect';

const billingRoot = (state) => state.billing;
export const selectBilling = createSelector([billingRoot], (billing) => billing);

// Public 
// ---

export const selectPlans = createSelector([billingRoot], (billing) => billing.plans);
export const selectPlanByRefOrId = (referenceOrId) => createSelector(
  selectPlans, (plans) => referenceOrId && plans.result.find(
    p => (referenceOrId===p.reference) || (referenceOrId===p._id)));


// Private (Account)
// ---

export const selectCart = createSelector([billingRoot], (billing) => billing.cart);
export const selectBillingAccount = createSelector([billingRoot], (billing) => billing.account);
export const selectSubscriptions = createSelector(selectBillingAccount, (account) => account.result.subscriptions);
export const selectTransactions = createSelector(selectBillingAccount, (account) => account.result.transactions);
export const selectSubscription = createSelector(selectBillingAccount, (account) => account.result.subscription);
export const selectCheckout = createSelector(selectBillingAccount, (account) => account.result.checkout);

// Careful: subscriptions have no "reference" field
export const selectSubscriptionById = (subscriptionId) => createSelector( 
  selectSubscriptions, (subscriptions) => subscriptions.find(s => subscriptionId === s._id));

// result from the external payment provider either directly ie. billingActions.checkPayment(),
// or thru the gateway using billingActions.checkout()
export const selectPaymentResult = createSelector(selectCheckout, (checkout) => checkout.paymentResult);

export const selectTransactionByRef = (reference, match={}) => createSelector(
  selectTransactions, (transactions) => {
    const transaction = reference && transactions.find(
      t => reference.toLowerCase() === t.reference.toLowerCase().replace(/\=+$/g), '');
    return isMatch(transaction, match) ? transaction : null;
  });


