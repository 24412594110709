import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PublicRoute from './PublicRoute';
import PageLoader from '@/components/PageLoader';
import Localization from '@/locale/Localization';


const Login = lazy(() => import(/*webpackChunkName:'LoginPage'*/ '@/pages/Login'));
const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/NotFound'));
const Register = lazy(() => import(/*webpackChunkName:'RegisterPage'*/ '@/pages/Register'));
const Verify = lazy(() => import(/*webpackChunkName:'RegisterPage'*/ '@/pages/Verify'));
const Pricing = lazy(() => import(/*webpackChunkName:'PricingPage'*/ '@/pages/Pricing'));
const Subscribe = lazy(() => import(/*webpackChunkName:'PricingPage'*/ '@/pages/Subscribe'));


export default function AuthRouter() {
  const location = useLocation();
  return (
    <Localization>
      <Suspense fallback={<PageLoader />}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            <PublicRoute component={Pricing} path="/pricing" exact redirectTo='/billing'  />
            <PublicRoute component={Login} path="/login" exact />
            <PublicRoute component={Register} path="/register" exact />
            <PublicRoute component={Verify} path="/verify/:email/:authType" exact />
            <Route path="/" exact render={() => <Redirect to="/pricing" />}  />
            <Route component={Subscribe} path="/subscribe" exact />

            <Route path="*" component={NotFound} render={() => <Redirect to="/notfound" />} />
          </Switch>
        </AnimatePresence>
      </Suspense>
    </Localization>

  );
}
