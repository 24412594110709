import { useSelector, useDispatch } from "react-redux";
import { billingAction } from "../../redux/billing/actions";
import { selectTransactionByRef } from "../../redux/billing/selectors";
import { useEffect, useRef } from "react";


/***
 * Poll for transaction (eg. payment) from the gateway
 * Fetch transactions and active subscriptions from account every 500ms to the state

  { "status": "pending",
    "reference": "test-cinetpay",
    "dateCreated": "2023-12-05T18:40:29.478Z",
    "account": "655a2040e89e0e4d03e26349",
    "message": "SUCCESS",
    "subscription": {
      "billing":"year","_id":"655ca1e6f7e8a4b3dd125920","dateCreated":"2023-11-21T12:26:14.071Z",
      "plan":"655a19b40d21ae2474327fb5","dateExpires":"2023-12-24T06:56:47.577Z"
    },
    "metadata": { 
      "amount":"100","currency":"XOF","status":"ACCEPTED","payment_method":"OM",
      "description":"Paiement abonnement","metadata":null,"operator_id":"MP220915.2257.A76307",
      "payment_date":"2022-09-15 22:57:15","fund_availability_date":"2022-09-17 00:00:00"
    }
  }

*/
const useTransaction = (reference, match={}) => {

  const dispatch = useDispatch();
  const transaction = useSelector(selectTransactionByRef(reference, match));

  // Clock with stable ref
  const clock = useRef();
  useEffect(() => {
    clock.current = setInterval(() => { 
      reference && dispatch(billingAction.getBillingAccount());
    }, 500);
    return () => clearInterval(clock.current);
  }, []);

  // Use that stable timer ref to stop polling
  // once a settled transaction is found ('failed' or 'success')
  useEffect(() => { 
    if(transaction && transaction.status !== 'pending') 
      clearInterval(clock.current);
  }, [transaction])


  return transaction
}

export default useTransaction;