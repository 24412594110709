import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import { rootReducer, initialState } from './rootReducer';
import storePersist from './storePersist';
import { merge } from 'lodash';

const logger = createLogger();
let middleware = [thunk];

let configStore = applyMiddleware(...middleware);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware];
  configStore = composeEnhancers(applyMiddleware(...middleware));
}

const persistedState = storePersist.get('auth') ? merge(initialState, {
  auth: { ...storePersist.get('auth'), isLoggedIn: storePersist.get('isLoggedIn') },
  billing: storePersist.get('billing') || {},
}) : {};

const store = createStore(rootReducer, persistedState, configStore);

export default store;
