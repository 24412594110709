import { merge } from 'lodash';


export function updateLocalStorage(key, data) {

  var existing = localStorage.getItem(key);
  existing = existing ? JSON.parse(existing) : {};
  merge(existing, data)
  localStorage.setItem(key, JSON.stringify(existing));

}