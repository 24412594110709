import { notification } from 'antd';
import history from '@/utils/history';
import codeMessage from './codeMessage';


// Caches HTTP status
let cached = {
  hasValidSubscription: false,
  jwtExpired: false
};

const errorHandler = (error) => {

  const { response } = error;
  if (response && response.status) {

    const message = response.data && response.data.message;
    const errorText = message || codeMessage[response.status];
    const { status } = response;
    
    // Notification shown iff any of cached state was observed
    notification.config({ duration: 10, });
    !Object.values(cached).some(x => !!x) && notification.error({
      message: `Request error ${status}`,
      description: errorText,
    });

    // JWT expired
    if (response.data && response.data.jwtExpired) {
      cached.jwtExpired = true
      history.push('/logout');
    }
    // Payment Required
    else if(+response.status === 402) {
      cached.hasValidSubscription = true;
      history.push('/pricing');
    }

    return response.data;

  } else {
    notification.config({
      duration: 5,
    });
    notification.error({
      message: 'No internet connection',
      description: 'Cannot connect to the server, Check your internet network',
    });
    return {
      success: false,
      result: null,
      message: 'Cannot connect to the server, Check your internet network',
    };
  }
};

export default errorHandler;
