import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCart, selectPlans, selectSubscriptions } from "@/redux/billing/selectors";
import { billingAction } from "@/redux/billing/actions";


export default function useBilling() {

  const dispatch = useDispatch();
  const { result: plans } = useSelector(selectPlans);
  const { result: cart } = useSelector(selectCart);
  const subscriptions = useSelector(selectSubscriptions);

  useEffect(() => {
    // TODO: populate cart from server
    dispatch(billingAction.list({ keyState: 'plans' }));
    dispatch(billingAction.getBillingAccount());
  }, []);

  return { plans, subscriptions, cart }
}