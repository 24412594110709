


import { get } from 'lodash';
import { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { billingAction } from "../../redux/billing/actions";
import { selectPaymentResult } from "../../redux/billing/selectors";

/***
 * Obtain status of a payment from the Provider (eg. CinetPay) directly, through the gateway.
 * Use the returned `checkPayment()` callback to run the request asynchronously.

  GET http://localhost:3034/api/payment/PAYMENT-REFERENCE/check
  {
    "code": "602",
    "message": "INSUFFICIENT_BALANCE",
    "data": {
      "amount": "100",
      "currency": "XOF",
      "status": "REFUSED",
      "payment_method": "OMSN",
      "description": "Leeram ERP techoutlooks on-demand (100/once ends 1/11/2024).",
      "metadata": "{\"account_id\":\"techoutlooks\",\"subscription\":{\"billing\":\"once\",\"dateCreated\":\"2023-11-21T12:26:14.071Z\",\"_id\":\"655ca1e6f7e8a4b3dd125920\",\"plan\":\"on-demand\",\"dateExpires\":\"2024-01-11T11:35:13.439Z\"}}",
      "operator_id": null,
      "payment_date": "2023-12-12 14:02:59",
      "fund_availability_date": "2023-12-21 00:00:00"
    },
    "api_response_id": "1702396781.5603"
  }
*/
const useCheckPayment = (reference) => {

  const dispatch = useDispatch();
  const paymentResult = useSelector(selectPaymentResult);
  const payment = useMemo(() => get(paymentResult, 'payment_status'), [paymentResult])
  const checkPayment = useCallback(() => 
    () => reference && dispatch(billingAction.checkPayment(reference)), [reference])

  return [payment, checkPayment]
}

export default useCheckPayment;