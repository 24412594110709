import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Menu, Dropdown } from 'antd';
import Notifications from '@/components/Notification';
import SelectLanguage from '@/components/SelectLanguage';
import useLanguage from '@/locale/useLanguage';

import {
  AppstoreOutlined,
  SettingOutlined,
  DollarOutlined,
  MailOutlined,
  LogoutOutlined,
  BellOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import photo from '@/style/images/photo.png';

import { selectCurrentAdmin } from '@/redux/auth/selectors';
import history from '@/utils/history';
import uniqueId from '@/utils/uinqueId';



export default function HeaderContent() {

  const translate = useLanguage();
  const currentAdmin = useSelector(selectCurrentAdmin);
  console.log('🚀 ~ file: index.jsx:23 ~ HeaderContent ~ currentAdmin:', currentAdmin);

  const profileDropdown = (
    <div className="profileDropdown whiteBox shadow" style={{ minWidth: '200px' }}>
      <div className="pad15" onClick={() => history.push('/profile')} style={{ cursor: 'pointer' }}>
        <Avatar
          size="large"
          className="last"
          src={currentAdmin?.photo || photo}
          style={{ float: 'left' }}
        />
        <div className="info">
          <p className="strong">
            {currentAdmin?.name} {currentAdmin?.surname}
          </p>
          <p>{currentAdmin?.email}</p>
        </div>
      </div>
      <div className="line"></div>

      <div>
        <Menu>
          <Menu.Item
            icon={<SettingOutlined />}
            key={`${uniqueId()}`}
            onClick={() => history.push('/profile')}
          >
            { translate('profile_settings') }
          </Menu.Item>
          <Menu.Item
            icon={<DollarOutlined />}
            key={`${uniqueId()}`}
            onClick={() => history.push('/billing')}
          >
            { translate('billing_settings') }
          </Menu.Item>
          <Menu.Item
            icon={<SettingOutlined />}
            key={`${uniqueId()}`}
            onClick={() => history.push('/settings/')}
          >
            { translate('app_settings') }
          </Menu.Item>
        </Menu>
      </div>
      <div className="line"></div>
      <div>
        <Menu>
          <Menu.Item
            icon={<LogoutOutlined />}
            key={`${uniqueId()}`}
            onClick={() => history.push('/logout')}
          >
            { translate('logout') }
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <div className="headerIcon" style={{ position: 'absolute', right: 0, zIndex: '99' }}>
      <Dropdown overlay={profileDropdown} trigger={['click']} placement="bottomRight">
        <Avatar className="last" src={currentAdmin?.photo || photo} />
      </Dropdown>

      {/* <Avatar icon={<AppstoreOutlined />} /> */}

      <Dropdown overlay={<Notifications />} trigger={['click']} placement="bottomRight">
        {/* <Badge dot> */}
        <Avatar icon={<BellOutlined />} />
        {/* </Badge> */}
      </Dropdown>
      <SelectLanguage />
    </div>
  );
}
