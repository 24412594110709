import { useDispatch, useSelector } from 'react-redux';

import languages from '@/locale/languages';
import { selectLangCode } from '@/redux/translate/selectors';

// import Notifications from '@/components/Notification';

import { translateAction } from '@/redux/translate/actions';
import useLanguage from '@/locale/useLanguage';
import useDeviceDetection from "@/hooks/useDeviceDetection"

import { Select } from 'antd';
import styles from './SelectLanguage.module.scss'


const SelectLanguage = () => {
  const translate = useLanguage();
  const dispatch = useDispatch();

  const langCode = useSelector(selectLangCode);
  const device = useDeviceDetection();

  const isMobile = device === 'mobile';


  return (
    <Select
      className={styles.root}
      showSearch
      placeholder={translate('select language')}
      defaultValue={langCode}
      style={{ float: 'right' }}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? '').includes(input.toLowerCase())}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().startsWith((optionB?.label ?? '').toLowerCase())
      }
      onSelect={(value) => {
        dispatch(translateAction.translate(value));
      }}
    >
      {languages.map((language) => (
        <Select.Option
          key={language.value}
          value={language.value}
          label={language.label.toLowerCase()}
          disabled={language.disabled}
        >
          <div className="demo-option-label-item">
            {!isMobile && (
              <span role="img" aria-label={language.label}>
                {language.icon}
              </span>
            )}
            {/* <span className='hidden sm:inline'>{language.label}</span>
            <span className='sm:hidden'>{language.value.substring(0,2)}</span> */}
            {!isMobile ? 
              <span className={styles.label}>{language.label}</span> :
              <span className={styles.labelsm}>{language.value.substring(0,2).toUpperCase()}</span>}
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectLanguage;
