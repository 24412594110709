import React, { useLayoutEffect, lazy, Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import PageLoader from '@/components/PageLoader';
import { useDispatch } from 'react-redux';
import { settingsAction } from '@/redux/settings/actions';
import { updateUI } from '@/redux/notify/actions';
import { routesConfig } from './RoutesConfig';
import Localization from '@/locale/Localization';
import { useBilling } from '@/hooks/billing';

const Logout = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ '@/pages/Logout'));
const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/NotFound'));
const Pricing = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ '@/pages/Pricing'));
const Subscribe = lazy(() => import(/*webpackChunkName:'SubscribePage'*/ '@/pages/Subscribe'));

const SubMenuRouter = ({ subMenuRouter }) => {
  subMenuRouter.map((subMenu) => {
    console.log('🚀 ~ file: AppRouter.jsx ~ line 25 ~ routeItem.hasSubMenu.map ~ subMenu', subMenu);

    return (
      <PrivateRoute
        key={subMenu.component}
        path={subMenu.path}
        exact={subMenu.exact || true}
        component={lazy(() =>
          import(/* webpackChunkName: "[request]" */ `@/pages/${subMenu.component}`)
        )}
      />
    );
  });
};

export default function AppRouter() {


  useLayoutEffect(() => {
    dispatch(settingsAction.list({ entity: 'setting' }));
  }, []);

  useBilling();
  const location = useLocation();
  const dispatch = useDispatch();

  return (
    <Localization>
      <Suspense fallback={<PageLoader />}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Switch location={location} key={location.pathname}>
            {routesConfig.map((routeItem) => {
              return (
                <PrivateRoute
                  key={routeItem.component}
                  path={routeItem.path}
                  exact={routeItem.exact || true}
                  component={lazy(() =>
                    import(/* webpackChunkName: "[request]" */ `@/pages/${routeItem.component}`)
                  )}
                />
              );
            })}
            <PublicRoute path="/login" render={() => <Redirect to="/" />} exact />
            <Route component={Logout} path="/logout" exact />
            <Route component={Pricing} path="/pricing" exact />
            <Route component={Subscribe} path="/subscribe" exact />
            {/* <PublicRoute path="/" component={Pricing} exact render={
              () => {
                const dispatch = useDispatch();
                dispatch(updateUI('navigation', { active: false }))
                return <Pricing />
              }
            } /> */}
            <Route path="*" component={NotFound} render={() => <Redirect to="/notfound" />} />
          </Switch>
        </AnimatePresence>
      </Suspense>
    </Localization>

  );
}
