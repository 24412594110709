import { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getRecommended } from 'redux/billing/helpers';


const useRecommended = () => {
  const { plans, account } = useSelector(s => s.billing)  
  const { subscriptions } = account.result
  const [products, setProducts] = useState([]);
  

  useLayoutEffect(() => {
    setProducts(() => getRecommended(plans.result, subscriptions))
  }, [plans, subscriptions])

  return products;
}

export default useRecommended