import { BILLING_BASE_URL } from '@/config/serverApiConfig';

import errorHandler from '@/request/errorHandler';
import successHandler from '@/request/successHandler';


async function run({ path, method, postData }) {
  try {
    const response = await fetch(BILLING_BASE_URL + `${path}?timestamp=${new Date().getTime()}`, {
      method,             // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',       // no-cors, *cors, same-origin
      cache: 'no-cache',  // *default, no-cache, reload, force-cache, only-if-cache
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(postData), // body data type must match "Content-Type" header
    });

    const { status } = response;
    const result = await response.json();
    const data = { result, success: response.ok, message: result.description || result.message };

    successHandler({ data, status }, {
      notifyOnSuccess: false,
      notifyOnFailed: true,
    });

    return data

  } catch (error) {

    return errorHandler(error);
  }
};

export default {

  /*** Get billing resources
    GET http://localhost:3034/api/plans/
    GET http://localhost:3034/api/services
    GET http://localhost:3034/api/users
    GET http://localhost:3034/api/users/654cec3eb433a6f64800cc30/subscriptions
   */
  list: async (path) => run({path, method: 'GET'}),

  /***
   * Check payment status directly with the provider eg. CinetPay, Stripe
   */
  checkPayment: async ({ paymentReference }) => {    
    return run({ 
      method: 'GET', 
      path: `payment/${paymentReference}/check`
    });
  },

  /*** Checkout
   * curl -X POST http://localhost:3034/api/accounts/:accountReference/subscriptions \ 
   *    -H "Content-Type: application/json" \
   *    -d '{ "plan": "on-demand", "paymentMethod": "pm_123", "billing": "month" }'
   * 
   * Nota:
   *  - 'billing' defaults to "month".
   */
  checkout: async ({ accountReference, returnUrl, ...postData }) => {    
    return run({ 
      postData, method: 'POST', 
      path: `accounts/${accountReference}/subscriptions?returnUrl=${returnUrl}`
    });
  },

  // FIXME: implement real request
  saveCart: async ({ accountReference, ...postData }) => {
    return {success: true, result: postData }
  },

  // FIXME: implement real request
  resetCart: async ({ accountReference, ...postData }) => {
    return {success: true, result: null }
  }

}

