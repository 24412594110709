import { pick } from 'lodash';
import { selectAppSettings } from "@/redux/settings/selectors";
import { selectCurrentAdmin } from "@/redux/auth/selectors";
import { updateLocalStorage } from '@/utils';
import * as actionTypes from './types';
import billingService from './billing.service';
import { selectCart } from './selectors';
import { notification } from 'antd';


const STORAGE_KEY = 'billing';


export const billingAction = {

  resetState: () => (dispatch) => {
    dispatch({
      type: actionTypes.RESET_STATE,
    });
  },

  // keyState = 'plan' | 'service' | 'user' | 'account'
  list: ({keyState}) => async (dispatch) => {

    dispatch({ 
      type: actionTypes.REQUEST_LOADING, 
        payload: null,
        keyState,      
    });

    let data = await billingService.list(keyState);
    if (data.success === true) {
      updateLocalStorage(STORAGE_KEY, { [keyState] : data });
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: data.result,
        keyState,
      });

    } else {
      dispatch({
        type: actionTypes.REQUEST_FAILED,
        payload: [],
        keyState,
      });
    }
  },

  getBillingAccount: () => async (dispatch, getState) => {

      const keyState = 'account';
      dispatch({ 
        type: actionTypes.REQUEST_LOADING, 
          payload: null,
          keyState,      
      });

      const { subdomain: accountReference  } = selectCurrentAdmin(getState())
      if (accountReference) {
        const data = await billingService.list(`accounts/${accountReference}`);
        if (data.success === true) {
          updateLocalStorage(STORAGE_KEY, { [keyState] : data });
          dispatch({
            type: actionTypes.REQUEST_SUCCESS,
            payload: data.result,
            keyState,
          });
        } else {
          dispatch({
            type: actionTypes.REQUEST_FAILED,
            payload: { subscriptions: [], transactions: [], paymentResult: {} },
            keyState,
          });
        }
      } 
      // else {
      //     notification.error({ message: `Auth error`,
      //     description: 'Not logged in',
      //   });
      // }
  },

  checkPayment: (paymentReference) => async (dispatch) => {
    const keyState = 'account';

    dispatch({ 
      type: actionTypes.REQUEST_LOADING, 
        payload: null,
        keyState,      
    });

    let data = await billingService.checkPayment(paymentReference);
    if (data.success === true) {
      updateLocalStorage(STORAGE_KEY, { [keyState] : data });
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: { paymentResult: { "payment_status": data.result} },
        keyState,
      });

    } else {
      dispatch({
        type: actionTypes.REQUEST_FAILED,
        payload: [],
        keyState,
      });
    }
  },

  /***
   * Obtain payment link from the payment provider (eg. CinetPay) via the gateway.
   * Yields subscriptions instead, iff `?ignorePaymentProvider=true` (payment bypass)
   */
  checkout: ({ cart, returnUrl }) => 
  async (dispatch, getState) => {

    const { plan, billing, dateExpires, paymentMethod } = cart;
    const keyState = 'account';
    dispatch({ 
      type: actionTypes.REQUEST_LOADING, 
      payload: null,
      keyState,      
    });

    const state = getState();
    const admin = selectCurrentAdmin(state)

    let data = await billingService.checkout({  
      // subscription info
      accountReference: admin.subdomain, plan, paymentMethod, dateExpires, billing,
      // url the provider (eg. cinetpay) redirects to 
      returnUrl, 
      // user info to appear in billing records
      ...pick(admin, [ 
        'name', 'surname', 'email', 'subdomain']),   
      ...pick(selectAppSettings(state), [
        'company_address', 'company_country', 'company_phone', 'company_cell'])
    });

    if (data.success === true) {
      // don't alter the live subscription in redux if a subcription wasn't created 
      // (deferred thru payment counter, free subscripion), ie. checkout.subscription===null
      let { subscriptions, ...checkout } = data.result
      const result = { subscriptions, ...(checkout.subscription && {subscription: checkout.subscription}), checkout }
      updateLocalStorage(STORAGE_KEY, { [keyState] : result });
      dispatch({
        type: actionTypes.REQUEST_SUCCESS,
        payload: result,
        keyState,
      });

    } else {
      dispatch({
        type: actionTypes.REQUEST_FAILED,
        payload: { subscriptions: [], paymentResult: {} },
        keyState,
      });
    }
  },

  /***
   * saveCart({ plan: <PLAN-REFERENCE>, billing: 'once'|'month'|'year' })
   */
  saveCart: (product) => 
    async (dispatch, getState) => {

      const cart = { ...selectCart(getState()), ...{ result: product }}
      const keyState = 'cart';

      dispatch({ 
        type: actionTypes.REQUEST_LOADING, 
        payload: null,
        keyState,      
      });

      const { subdomain: accountReference } = selectCurrentAdmin(getState())
      if (accountReference) {
        const data = await billingService.saveCart({accountReference, cart});
        if (data.success === true) {
          updateLocalStorage(STORAGE_KEY, { cart });
          dispatch({
            type: actionTypes.REQUEST_SUCCESS,
            payload: cart.result,
            keyState,
          });
      } 

      }
  },
  resetCart: (data) => 
    async (dispatch, getState) => {

      dispatch({ 
        type: actionTypes.REQUEST_LOADING, 
          payload: null,
          keyState,      
      });

      const { subdomain: accountReference } = selectCurrentAdmin(getState())
      await billingService.resetCart({accountReference});
      const keyState = 'cart';

      if (data.success === true) {
        updateLocalStorage(STORAGE_KEY, { cart: {}});
        dispatch({
          type: actionTypes.RESET_ACTION,
          payload: null,
          keyState,
        });

      }
  },
};
