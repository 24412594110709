/***
 * API config for the ERP and Billing backends
 * Eg.
    REACT_APP_DEV_REMOTE=remote NODE_OPTIONS=--openssl-legacy-provider \
    REACT_APP_BILLING_SERVER=http://192.168.1.199:3034 \
    REACT_APP_BACKEND_SERVER=http://192.168.1.199:8888 \
    npm start
 */

export const FRONTEND_URL =
  process.env.NODE_ENV == 'production' || process.env.REACT_APP_DEV_REMOTE == 'remote'
    ? process.env.REACT_APP_FRONTEND 
    : 'http://localhost:3000';

export const API_BASE_URL =
  process.env.NODE_ENV == 'production' || process.env.REACT_APP_DEV_REMOTE == 'remote'
    ? process.env.REACT_APP_BACKEND_SERVER + '/api/'
    : 'http://localhost:8888/api/';

export const DOWNLOAD_BASE_URL =
  process.env.NODE_ENV == 'production' || process.env.REACT_APP_DEV_REMOTE == 'remote'
    ? process.env.REACT_APP_BACKEND_SERVER + '/download/'
    : 'http://localhost:8888/download/';

export const BILLING_BASE_URL =
  process.env.NODE_ENV == 'production' || process.env.REACT_APP_DEV_REMOTE == 'remote'
    ? process.env.REACT_APP_BILLING_SERVER + '/api/'
    : 'http://localhost:3034/api/';
    
export const ACCESS_TOKEN_NAME = 'x-auth-token';
